import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useContext } from "./AppContext";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Api from "./Api";
// pages
import Home from "./pages/Home";
import Partners from "./pages/Partners";
import Business from "./pages/Business";
import Development from "./pages/Development";
import Catalogs from "./pages/Catalogs";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Products from "./pages/Products";
import Item from "./pages/Item";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";
// notifications
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
// Whatsapp
import WhatsAppBubble from "./components/WhatsAppBubble/WhatsAppBubble";

const App = () => {
  const { dispatch } = useContext();

  useEffect(() => {
    // global
    const pages = [
      {
        id: "corporativo",
        name: "Corporativo",
        link: "/empresa",
      },
      {
        id: "clientes",
        name: "Clientes",
        link: "/clientes",
      },
      {
        id: "catalogos",
        name: "Catálogos",
        link: "/catalogos",
      },
      {
        id: "desarrollo",
        name: "Desarrollo de producto",
        link: "/desarrollo-de-producto",
      },
      {
        id: "contacto",
        name: "Contacto",
        link: "/contacto",
      },
    ];
    dispatch({ type: "UPDATE_PAGES", payload: pages });

    Api.get("categories")
      .then((res) => {
        dispatch({ type: "UPDATE_CATEGORIES", payload: res.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ReactNotification />
      <WhatsAppBubble
        phone="+525591662709"
        message="Hola, me interesa cotizar varios productos."
      />
      <ScrollToTop />
      <Header />
      {/* React router */}
      <Routes>
        <Route index element={<Home title="¡Bienvenido!" />} />
        <Route path="clientes" element={<Partners title="clientes" />} />
        <Route path="empresa" element={<Business title="Corporativo" />} />
        <Route
          path="desarrollo-de-producto"
          element={<Development title="Desarrollo de producto" />}
        />
        <Route path="catalogos" element={<Catalogs title="Catálogos" />} />
        <Route path="contacto" element={<Contact title="Contacto" />} />
        <Route path="privacidad" element={<Privacy title="Privacidad" />} />
        <Route path="categorias" element={<Products title="Productos" />} />
        <Route
          path="categorias/:categoryId"
          element={<Products title="Productos" />}
        />
        <Route path="productos" element={<Products title="Productos" />} />
        <Route
          path="productos/:productId"
          element={<Item title="Productos" />}
        />
        <Route path="cart" element={<Cart title="Cotización" />} />
        <Route path="checkout" element={<Checkout title="Cotización" />} />
        <Route path="*" element={<NotFound title="404" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
