import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Image from "react-bootstrap/Image";

const Breadcrumbs = ({ photo, crumbs }) => {
  return (
    <div className="breadcrumb-all-page">
      <div className="bn-top">
        <div className="container_img_bread no-img-cate img-breadcrumb-on-fieldthemecustomizer">
          <Image src={photo} className="img-responsive back_ct_img" />
          <div className="breadcrumb-abs">
            <Container>
              <Breadcrumb>
                {crumbs.map(({ name, path }, key) =>
                  key + 1 === crumbs.length ? (
                    <Breadcrumb.Item key={key} active>
                      {name}
                    </Breadcrumb.Item>
                  ) : (
                    <div key={key}></div>
                    // <Breadcrumb.Item
                    //   key={key}
                    //   linkAs={Link}
                    //   linkProps={{ to: path }}
                    // >
                    //   {name}
                    // </Breadcrumb.Item>
                  )
                )}
              </Breadcrumb>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  photo: PropTypes.string,
  crumbs: PropTypes.array,
};

Breadcrumbs.defaultProps = {
  photo: "",
  crumbs: [],
};

export default Breadcrumbs;
