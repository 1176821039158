import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import headerImg from "./../assets/img/business/header.jpg";
import racksLogoImg from "./../assets/img/business/logo-racks.png";
import mvvImg from "./../assets/img/business/mvv.jpg";
import pvcImg from "./../assets/img/business/pvc.jpg";
import workersImg from "./../assets/img/business/workers.jpg";
import cartaRymsaFile from "./../assets/docs/carta_rymsa.pdf";

const Business = ({ title, crumbs }) => {
  PageTitle(title);

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <header className="page-header">
              <h1>UNIVERPLAST</h1>
            </header>
            <section className="page-content page-cms page-cms-4">
              <div id="cms-about-us" className="block">
                <div className="cms-row block block_top clearfix">
                  <Row className="pb-3">
                    <Col lg={6} md={12} className="part_1">
                      <div className="cms-box cms-box-img">
                        <Image src={workersImg} className="img-responsive" />
                      </div>
                    </Col>
                    <Col lg={6} md={12} className="part_2">
                      <h3 className="page-subheading">Nuestra historia</h3>
                      <div className="cms-box">
                        <p>
                          Como resultado de más de 35 años de trabajo en la
                          industria del plástico en 1983 nace Grupo UNIVERPLAST
                          México, una empresa 100% Mexicana Orientada al Cliente
                          y a la solución de sus problemas, ofreciendo los
                          productos plásticos de la más alta calidad e
                          innovación para la INDUSTRIA y el HOGAR.
                        </p>
                        <p>
                          Grupo UNIVERPLAST México está consciente de las nuevas
                          tendencias de mercado, por lo que además de contar con
                          la más amplia variedad de Artículos Plásticos,
                          desarrollamos NUEVOS PRODUCTOS que nos permiten
                          implementar soluciones que ayudan a su empresa a
                          concretar de manera eficiente cada uno de sus
                          proyectos.
                        </p>
                        <p>
                          Siempre reinventándonos buscamos superar las
                          expectativas de nuestros clientes, es por ello que con
                          el fin de garantizar la calidad en nuestros productos
                          y servicios extendemos los Certificados de Calidad de
                          cada producto y Garantía sobre cualquier Defecto de
                          Fabricación del mismo, además de que diversos
                          productos cuentan con diferentes normas que garantizan
                          la seguridad para su uso.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
            <section className="page-content page-cms page-cms-4">
              <div id="cms-about-us" className="block">
                <div className="cms-row block block_top clearfix">
                  <Row className="pb-3">
                    <Col lg={6} md={12}>
                      <h3 className="page-subheading">
                        Las bases que nos consolidan
                      </h3>
                      <div className="cms-box">
                        <Tabs
                          defaultActiveKey="mision"
                          transition={false}
                          id="noanim-tab-example"
                          className="mb-3"
                        >
                          <Tab eventKey="mision" title="Misión">
                            <p>
                              Grupo UNIVERPLAST México es una empresa
                              comprometida en solucionar las diferentes
                              problemáticas del mercado con un enfoque total
                              hacia el cliente, a través de los productos más
                              eficientes dentro de la INDUSTRIA DEL PLÁSTICO,
                              que impulsen el desarrollo de su empresa, mediante
                              soluciones integrales que permitan un desarrollo
                              de su organización y el entorno económico en el
                              que se desenvuelva.
                            </p>
                          </Tab>
                          <Tab eventKey="vision" title="Visión">
                            <p>
                              Establecernos como la empresa Líder en Fabricación
                              de Artículos Plásticos Industriales y del Hogar,
                              promoviendo nuestros valores de Servicio Oportuno,
                              Atención Personalizada y Calidad Total en cada uno
                              de nuestros procesos y productos, contribuyendo al
                              crecimiento económico del sector y de nuestro
                              país, así como la generación de nuevos empleos que
                              le proporcionen un valor agregado a nuestra
                              sociedad.
                            </p>
                          </Tab>
                          <Tab eventKey="valores" title="Valores">
                            <ol>
                              <li>Orientación al cliente.</li>
                              <li>Nuestra gente.</li>
                              <li>Compromiso.</li>
                              <li>Experiencia.</li>
                              <li>Actitud de servicio.</li>
                              <li>Calidad total.</li>
                            </ol>
                          </Tab>
                          <Tab eventKey="principios" title="Principios">
                            <ol>
                              <li>Servicio oportuno.</li>
                              <li>Atención personalizada.</li>
                              <li>Calidad total.</li>
                            </ol>
                          </Tab>
                          <Tab eventKey="decalogo" title="Decálogo">
                            <ol>
                              <li>
                                Servicio integral personalizado al cliente.
                              </li>
                              <li>Liderazgo en el mercado.</li>
                              <li>
                                Perfeccionamiento constante de nuestra cadena
                                productiva.
                              </li>
                              <li>
                                Participación e integración del personal en la
                                toma de decisiones.
                              </li>
                              <li>Comunicación interdepartamental.</li>
                              <li>Innovación constante.</li>
                              <li>
                                Manejo de materiales e insumos que le
                                proporcionen un óptimo rendimiento a cada uno de
                                nuestros productos.
                              </li>
                              <li>Tiempos de entrega puntuales.</li>
                              <li>
                                Alianzas estratégicas con proveedores que le
                                proporcionen un real valor agregado a su
                                empresa.
                              </li>
                              <li>
                                Enfoque total del ciclo de mejora continua.
                              </li>
                            </ol>
                          </Tab>
                        </Tabs>
                      </div>
                    </Col>
                    <Col lg={6} md={12}>
                      <div className="cms-box cms-box-img">
                        <Image src={mvvImg} className="img-responsive" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
            <section className="page-content page-cms page-cms-4">
              <div id="cms-about-us" className="block">
                <div className="cms-row block block_top clearfix">
                  <Row className="pb-3">
                    <Col lg={6} md={12} className="part_1">
                      <div className="cms-box cms-box-img">
                        <Image src={pvcImg} className="img-responsive" />
                      </div>
                    </Col>
                    <Col lg={6} md={12} className="part_2">
                      <h3 className="page-subheading">
                        Nuestra política de calidad
                      </h3>
                      <div className="cms-box">
                        <p>
                          En Grupo UNIVERPLAST México estamos conscientes de las
                          crecientes necesidades del mercado y de la capacidad
                          innovadora que debe generar nuestra empresa para
                          satisfacer y dar solución a todos los retos que impone
                          el cambiante mercado actual. Así pues, mediante la
                          atención de personal altamente capacitado, tenemos la
                          convicción de que la calidad de nuestros productos y
                          servicios respaldan el éxito de todos nuestros
                          proyectos y la seguridad para abastecer futuros
                          requerimientos orientados a revolucionar el mercado
                          del plástico.
                        </p>
                        <p>
                          Producimos una extensa gama de productos de plástico
                          bajo las más estrictas normas de calidad durante todo
                          el proceso de producción, desde la recepción de la
                          materia prima hasta las respectivas pruebas del
                          producto, con el objetivo de corroborar cabalmente los
                          estándares que ofrecemos para satisfacer y superar las
                          expectativas de nuestros clientes.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
            <section className="page-content page-cms page-cms-4">
              <div className="cms-row text-center block clearfix">
                <header className="page-header">
                  <h1>Grupo RYMSA</h1>
                </header>
                <div className="mb-4">
                  <Image src={racksLogoImg} width="200" height="138" />
                </div>
                <p className="des_title mb-3">
                  Formamos parte de RYMSA, una empresa con más de 35 años de
                  experiencia en el mercado que se especializa en proporcionar
                  las más eficientes <b>soluciones en logística</b> para el
                  mercado a través de racks industriales, estantería metálica,
                  speedi bilt y mobiliario en general.
                </p>
                <div className="mb-2">
                  <a href={cartaRymsaFile} target="_blank" rel="noreferrer">
                    <Button variant="primary">Carta de presentación</Button>
                  </a>
                </div>
              </div>
            </section>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Business;
