import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import headerImg from "./../assets/img/download/header.jpg";
import Treeview from "../components/Treeview";
import file0 from "./../assets/docs/Brochure_Grupo_UNIVERPLAST_Mx.pdf";
import file1 from "./../assets/docs/Cuadriptico_2018_-_Grupo_UNIVERPLAST_Mx.pdf";
import file2 from "./../assets/docs/HC_Contenedores_Cajas.pdf";
import file3 from "./../assets/docs/HC_Contenedores_CRejilla_IBC.pdf";
import file4 from "./../assets/docs/HC_Contenedores_Polycar.pdf";
import file5 from "./../assets/docs/Catalogo_2018_Botellas_y_Frascos_-_Grupo_UNIVERPLAST_Mx.pdf";
import file6 from "./../assets/docs/Catalogo_Cubetas_-_Grupo_UNIVERPLAST_Mx.pdf";
import file7 from "./../assets/docs/Catalogo_Garrafones_-_Grupo_UNIVERPLAST_Mx.pdf";
import file8 from "./../assets/docs/Catalogo_Tanques_-_Grupo_UNIVERPLAST_Mx.pdf";
import file9 from "./../assets/docs/HC_Tambores.pdf";

const Catalogs = ({ title, crumbs }) => {
  PageTitle(title);

  const fileList = [
    {
      text: "Presentaciones",
      nodes: [
        {
          text: "Presentación comercial",
          href: file0,
        },
        {
          text: "Cuadríptico",
          href: file1,
        },
      ],
    },
    {
      text: "Catálogos",
      nodes: [
        {
          text: "Contenendores",
          nodes: [
            {
              text: "Cajas",
              href: file2,
            },
            {
              text: "Contenedores con rejilla IBC",
              href: file3,
            },
            {
              text: "Contenedores polycar",
              href: file4,
            },
          ],
        },
        {
          text: "Envases",
          nodes: [
            {
              text: "Botellas y frascos",
              href: file5,
            },
            {
              text: "Cubetas",
              href: file6,
            },
            {
              text: "Garrafones",
              href: file7,
            },
            {
              text: "Tanques",
              href: file8,
            },
          ],
        },
        {
          text: "Envases",
          nodes: [
            {
              text: "Tambores",
              href: file9,
            },
          ],
        },
      ],
    },
  ];

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <header className="page-header">
              <h1>Archivos</h1>
            </header>
            <section id="content" className="page-content page-cms page-cms-4">
              <div className="block" style={{ textAlign: "left" }}>
                <div className="cms-row block block_top clearfix">
                  <Row className="pb-3">
                    <Col md={12} lg={6} className="mb-5">
                      <Treeview data={fileList} />
                    </Col>
                    <Col md={12} lg={6}>
                      <h3 className="page-subheading">Material de interés</h3>
                      <div className="cms-box">
                        <p>
                          En nuestro catálogo podrás encontrar productos
                          certificados por organismos internacionales de calidad
                          que te proporcionarán la máxima seguridad y confianza
                          para trabajar con nosotros. Así mismo nos
                          comprometemos a ofrecerte el enfoque y la atención
                          personalizada que como cliente esperas.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Catalogs;
