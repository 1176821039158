import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
// import "./theme.css";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
// context
import { ContextProvider } from "./AppContext";
import { CartProvider } from "react-use-cart";
// Analytics
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

ReactDOM.render(
  <Suspense fallback={<div></div>}>
    <ContextProvider>
      <CartProvider>
        <App />
      </CartProvider>
    </ContextProvider>
  </Suspense>,
  document.getElementById('root')
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
