import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import { useCart } from "react-use-cart";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import InputQuantity from "./../components/InputQuantity";
import headerImg from "./../assets/img/cart/header.jpg";

const Cart = ({ title, crumbs }) => {
  PageTitle(title);

  const navigate = useNavigate();
  const { isEmpty, totalUniqueItems, items, updateItemQuantity, removeItem } =
    useCart();

  const onQuantityChanged = (quantity) => {
    updateItemQuantity(quantity.id, quantity.quantity);
  };

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <Row className="mb-5">
              <Col lg={8} md={12}>
                {isEmpty ? (
                  <Card className="cart-empty text-center">
                    <Card.Body>
                      <h3 className="text-center">Tu carrito está vacío</h3>
                      <Card.Text className="text-center">
                        ¿No sabes qué cotizar? ¡Miles de productos te esperan!
                      </Card.Text>
                      <Link to="/categorias">
                        <Button variant="primary">Ver productos</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                ) : (
                  <Card className="cart-item-container mb-4">
                    <Card.Body>
                      <h5 className="mb-4">
                        Carrito (<span>{totalUniqueItems}</span>)
                      </h5>
                      {items.map((item, idx) => (
                        <div key={item.id} className="cart-item">
                          <Row className="mb-4">
                            <Col md={5} lg={3} xl={3}>
                              <div className="rounded mb-3 mb-md-0">
                                <a href={item.permalink}>
                                  <img
                                    className="img-fluid w-100"
                                    src={item.thumbnail}
                                    alt="item"
                                  />
                                </a>
                              </div>
                            </Col>
                            <Col md={7} lg={9} xl={9}>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <a href={item.permalink}>
                                    <h6>{item.title}</h6>
                                  </a>
                                  {item.variant.map((variant) => (
                                    <p
                                      key={variant.id}
                                      className="mb-3 text-muted text-uppercase small"
                                    >
                                      {variant.name} - {variant.value_name}
                                    </p>
                                  ))}
                                  <InputQuantity
                                    id={item.id}
                                    quantity={item.quantity}
                                    onQuantityChanged={onQuantityChanged}
                                  />
                                </div>
                              </div>
                              <div className="mt-3">
                                <Button
                                  variant="link"
                                  className="btn-remove ps-0"
                                  onClick={() => removeItem(item.id)}
                                >
                                  <i className="fa fa-trash"></i> Eliminar
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          {idx < items.length - 1 && <hr className="mb-4" />}
                        </div>
                      ))}
                    </Card.Body>
                  </Card>
                )}
              </Col>
              <Col lg={4} md={12}>
                {!isEmpty && (
                  <Card className="cart-info-panel mb-4">
                    <Card.Body>
                      <h5 className="mb-3">Completa tu orden</h5>
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigate("/checkout");
                        }}
                      >
                        <i className="fa fa-check me-1" />
                        Solicitar cotización
                      </Button>
                    </Card.Body>
                  </Card>
                )}
                <Card className="cart-warranty mb-4">
                  <Card.Body>
                    <h5 className="mb-4">Garantía</h5>
                    <ListGroup variant="flush">
                      <ListGroup.Item className="d-flex h6 px-0">
                        <i className="fa fa-file me-2"></i>Te enviamos tu
                        cotización vía telefónica y correo electrónico.
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex h6 px-0">
                        <i className="fa fa-lock me-2"></i>Protegemos tus datos
                        con cifrado y certificado TLS.
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex h6 px-0">
                        <i className="fa fa-address-card me-2"></i>Para
                        brindarte el servicio que mereces, la veracidad de tu
                        información es muy importante para nosotros.
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Cart;
