import { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

/**
 * Creates a treeview with given categories
 */
const $ = window.jQuery;
const CategorySelector = ({ data, onCategorySelect }) => {
  /**
   * Creates a formatted tree for use
   * in treeview plugin
   * @param {array} data
   * @returns
   */
  const formatData = useCallback((data) => {
    let values = [];

    const recursiveTree = (node) => {
      let fNode = {
        id: node.id,
        text: node.name,
        href: node.link,
        nodes: null,
      };
      if (node.childs) {
        fNode.nodes = formatData(node.childs);
      }
      values.push(fNode);
    };

    if (Array.isArray(data)) {
      data.forEach((node) => {
        recursiveTree(node);
      });
    }
    return values;
  }, []);

  useEffect(() => {
    $("#catTreeview").treeview({
      color: "#428bca",
      showBorder: false,
      expandIcon: "fa fa-plus",
      collapseIcon: "fa fa-minus",
      enableLinks: false,
      data: formatData([...data]),
      onNodeSelected: function (event, node) {
        if (node.href) {
          onCategorySelect(node);
        }
      },
    });

    $("#catTreeview").treeview("collapseAll", { silent: true });
  }, [data, formatData, onCategorySelect]);

  return <div id="catTreeview" />;
};

CategorySelector.propTypes = {
  data: PropTypes.array,
  onCategorySelect: PropTypes.func,
};

CategorySelector.defaultProps = {
  data: [],
  onCategorySelect: () => {},
};

export default CategorySelector;
