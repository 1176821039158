import PropTypes from "prop-types";
import placeholderImg from "./../assets/img/categories/placeholder.jpg";

/**
 * Display a list with cards
 * @param {object} item
 */
const ItemCardH = ({ item, asPlaceholder }) => {
  const { id, title, sku, permalink, thumbnail } = item;

  const placeholderCard = (
    <div key={id} className="horizontal-card ph">
      <div className="row">
        <div className="col-5 text-center">
          <svg className="mb-3" style={{ width: "100%", height: "150px" }}>
            <rect x="0" y="0" width="100%" height="100%" fill="#868e96" />
          </svg>
        </div>
        <div className="col-7">
          <div className="card-body">
            <p className="card-text placeholder-glow">
              <span className="placeholder col-7"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-4"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const dataCard = (
    <div key={id} className="horizontal-card">
      <a href={permalink}>
        <div className="row">
          <div className="col-5 text-center">
            <img
              className="card-img"
              src={thumbnail ? thumbnail : placeholderImg}
              alt=""
            />
          </div>
          <div className="col-7">
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{sku}</h6>
            </div>
          </div>
        </div>
      </a>
    </div>
  );

  return asPlaceholder ? placeholderCard : dataCard;
};

ItemCardH.propTypes = {
  item: PropTypes.object,
  asPlaceholder: PropTypes.bool,
};

ItemCardH.defaultProps = {
  item: {},
  asPlaceholder: false,
};

export default ItemCardH;
