import { useState } from "react";
import { useContext } from "./../AppContext";
import Api from "../Api";
import axios from "axios";
import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import headerImg from "./../assets/img/contact/header.jpg";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Check } from "lucide-react";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import PhoneNumberInput from "../components/PhoneNumberInput";
import es from "react-phone-number-input/locale/es.json";
import Select from "react-select";

let request;
const Contact = ({ title, crumbs }) => {
  PageTitle(title);

  const {
    state: { categories },
  } = useContext();
  const [categorySelect, setCategorySelect] = useState(null);

  // Translation
  const { t } = useTranslation(["form"]);

  // Modal
  const [show, setShow] = useState(false);
  const closeModal = () => setShow(false);
  const showModal = () => setShow(true);

  /**
   * Format a tree for select
   * @param {object} tree
   * @returns
   */
  const formatCategories = (tree) => {
    /**
     * Flatten a nested Associative Array (tree) to a List
     *
     * @param {object} tree
     * @param {string} key
     * @param {array} collection
     * @returns
     */
    var bfs = function (tree, key, collection) {
      if (!tree[key] || tree[key].length === 0) return;
      for (var i = 0; i < tree[key].length; i++) {
        var child = tree[key][i];
        var formatNode = {
          id: child.id,
          value: child.id,
          label: child.parent_id
            ? "  ".repeat(i + 1) + "↳ " + child.name
            : child.name,
        };
        collection[formatNode.id] = formatNode;
        bfs(child, key, collection);
      }
      return;
    };

    const formatTree = {
      childs: tree,
    };
    var flattenedCategories = [];

    bfs(formatTree, "childs", flattenedCategories);

    return flattenedCategories;
  };

  /**
   * Validation
   */
  const [isProcessing, setIsProcessing] = useState(false);
  const messageLength = 2000;
  const [textAreaWrited, setTextAreaWrited] = useState(0);
  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t("form:error.required", { field: t("form:field.full_name") })),
    category_id: yup.number(),
    phone: yup
      .string()
      .required(
        t("form:error.required", { field: t("form:field.phonecountry") })
      )
      .test(
        "test-phone",
        t("form:error.regex", { field: t("form:field.phonecountry") }),
        function (v, e) {
          return v && isPossiblePhoneNumber(v) ? true : false;
        }
      ),
    email: yup
      .string()
      .email(t("form:error.required", { field: t("form:field.email") }))
      .required(t("form:error.required", { field: t("form:field.email") })),
    message: yup
      .string()
      .max(
        messageLength,
        t("form:error.max", {
          field: t("form:field.message"),
          length: messageLength,
        })
      )
      .required(t("form:error.required", { field: t("form:field.message") })),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      category_id: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsProcessing(true);

      // sanitize inputs
      var purified = { ...values };
      purified["country_code"] = parsePhoneNumber(purified["phone"]).country;
      Object.keys(purified).forEach((k) => {
        values[k] = DOMPurify.sanitize(("" + values[k]).trim(), {
          USE_PROFILES: { html: false },
        });
      });

      // cancel previous request
      if (request) request.cancel();

      // create a new cancel token
      request = axios.CancelToken.source();

      Api.post("leads", purified, {
        cancelToken: request.token,
      })
        .then((res) => {
          // reset
          formik.resetForm();
          setCategorySelect(null);
          setTextAreaWrited(0);

          showModal();
        })
        .catch((error) => {})
        .finally(() => {
          setIsProcessing(false);
        });
    },
  });

  // items
  const placeList = [
    {
      name: "Casa matriz",
      address: {
        street: "Poniente 27A No. 4005",
        suburb: "Del Gas",
        town: "Azcapotzalco",
        city: "CDMX",
        zip: "02950",
      },
      phone: "5555560799",
      displayPhone: "(55) 5556 0799",
      map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15046.697435860096!2d-99.1555201!3d19.4696019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8f75332ab41%3A0x711e09c88fdae51e!2sUNIVERPLAST%20M%C3%A9xico!5e0!3m2!1ses-419!2smx!4v1623276323646!5m2!1ses-419!2smx",
    },
    {
      name: "Sucursal Cuitláhuac",
      address: {
        street: "Cuitláhuac 1626",
        suburb: "Aguilera",
        town: "Azcapotzalco",
        city: "CDMX",
        zip: "02900",
      },
      phone: "5555569308",
      displayPhone: "(55) 5556 9308",
      map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15046.445470145896!2d-99.1627025!3d19.4723157!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x12be3de86334b05f!2sRacks%20y%20Mobiliario%20Industrial!5e0!3m2!1ses-419!2smx!4v1623178302970!5m2!1ses-419!2smx",
    },
  ];

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <header className="page-header">
              <h1>Te esperamos</h1>
            </header>
          </section>
          <section className="page-content page-cms page-cms-4">
            <div id="cms-about-us" className="block">
              <div className="cms-row block block_top clearfix">
                {placeList.map(
                  ({ name, address, phone, displayPhone, map }, key) => (
                    <Row key={key} className="pb-5">
                      <Col lg={8} md={12} className="part_1">
                        <iframe
                          title={name}
                          src={map}
                          width="100%"
                          height="350"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                        ></iframe>
                      </Col>
                      <Col lg={4} md={12} className="part_2">
                        <h3 className="page-subheading">{name}</h3>
                        <div className="contact-box">
                          <p>
                            <i className="fa fa-map-pin" /> {address["street"]}
                            <br />
                            Col. {address["suburb"]}
                            <br />
                            Alc. {address["town"]}
                            <br />
                            {address["city"]} C.P. {address["zip"]}
                          </p>
                          <div className="mb-2">
                            <a
                              href={`tel:${phone}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button variant="primary">
                                <i className="fa fa-phone pr-1" />
                                {` ${displayPhone}`}
                              </Button>
                            </a>
                          </div>
                        </div>
                        <p className="des_content_post"></p>
                      </Col>
                    </Row>
                  )
                )}
                <div className="pt-3 pb-3">
                  <Row>
                    <Col lg={5} md={12}>
                      <h3 className="page-subheading">Contáctanos</h3>
                      <p>
                        Recibirás el mejor trato de nuestros expertos, deseamos
                        escuchar de ti.
                      </p>
                    </Col>
                    <Col lg={7} md={12}>
                      <Form onSubmit={formik.handleSubmit}>
                        <fieldset disabled={isProcessing}>
                          <Row className="mb-2">
                            <Form.Group as={Col} md="12" className="mb-2">
                              <FloatingLabel
                                label={t("form:required.full_name")}
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder={t("form:required.full_name")}
                                  value={formik.values.name}
                                  onChange={formik.handleChange}
                                  className={
                                    formik.touched.name && formik.errors.name
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.name &&
                                Boolean(formik.errors.name) ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.name}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                              <PhoneInput
                                name="phone"
                                labels={es}
                                defaultCountry="MX"
                                international
                                addInternationalOption={false}
                                countryCallingCodeEditable={false}
                                placeholder="XXX XXX XXXX"
                                value={formik.values.phone}
                                label={`${t("form:required.phonecountry")}`}
                                errors={formik.errors.phone}
                                className={
                                  formik.errors.phone ? "is-invalid" : null
                                }
                                inputComponent={PhoneNumberInput}
                                onChange={(e) =>
                                  formik.setFieldValue("phone", e)
                                }
                              />
                              {formik.errors.phone ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.phone}
                                </Form.Control.Feedback>
                              ) : null}
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                              <FloatingLabel label={t("form:required.email")}>
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder={t("form:required.email")}
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                  className={
                                    formik.touched.email && formik.errors.email
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.email &&
                                Boolean(formik.errors.email) ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.email}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-2">
                              <Form.Text className="text-muted">
                                Productos (Opcional)
                              </Form.Text>
                              <Select
                                name="category_id"
                                isSearchable={false}
                                isClearable={true}
                                isDisabled={isProcessing}
                                placeholder="Selecciona"
                                value={categorySelect}
                                options={formatCategories(categories)}
                                onChange={(e) => {
                                  const value = e ? e.value : null;
                                  formik.setFieldValue("category_id", value);
                                  setCategorySelect(e);
                                }}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="input-col">
                              <Form.Text className="text-muted">
                                Mensaje
                              </Form.Text>
                              <Form.Control
                                as="textarea"
                                name="message"
                                style={{
                                  height: "150px",
                                  resize: "none",
                                }}
                                placeholder=""
                                value={formik.values.message}
                                onChange={(e) => {
                                  setTextAreaWrited(
                                    e.currentTarget.value.length
                                  );
                                  formik.handleChange(e);
                                }}
                                className={
                                  formik.touched.message &&
                                  formik.errors.message
                                    ? "is-invalid"
                                    : null
                                }
                              />
                              {/* Left character count */}
                              <Form.Text
                                className={`${
                                  textAreaWrited >= messageLength
                                    ? "text-danger"
                                    : textAreaWrited >=
                                      messageLength -
                                        Math.round(messageLength * 0.07)
                                    ? "text-warning"
                                    : "text-success"
                                }`}
                              >
                                {messageLength - textAreaWrited}
                              </Form.Text>
                              {formik.touched.message &&
                              formik.errors.message ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.message}
                                </Form.Control.Feedback>
                              ) : null}
                            </Form.Group>
                          </Row>
                          <Button variant="primary" type="submit">
                            {!isProcessing
                              ? t("form:submit.send")
                              : t("form:submit.sending")}
                          </Button>
                        </fieldset>
                      </Form>
                      <Modal show={show} onHide={closeModal} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>Gracias</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="text-center">
                            <div
                              className="icon d-flex align-items-center justify-content-center bg-soft-success rounded-circle mx-auto"
                              style={{ height: "95px", width: "95px" }}
                            >
                              <h1 className="mb-0">
                                <Check size={48} className="align-middle" />
                              </h1>
                            </div>
                            <div className="p-3">
                              <h4>Recibimos tu mensaje</h4>
                              <p className="text-muted">
                                Nuestro equipo de venta se pondrá en contacto
                                contigo a la brevedad.
                              </p>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={closeModal}>
                            Cerrar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
