import { useEffect, createRef, useState } from "react";
import { useContext } from "./../../AppContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Accordion from "react-bootstrap/Accordion";
import { Link, NavLink } from "react-router-dom";
import logoFooter from "./../../assets/img/logo_ft.png";
import WindowDimensions from "../hooks/WindowDimensions";

const Footer = () => {
  const [breakpointUpdated, setBreakpointUpdated] = useState(false);
  const { height, width } = WindowDimensions();
  const contactAccRef = createRef();
  const categoriesAccRef = createRef();
  const otherAccRef = createRef();
  let contactAccBtn = null;
  let categoriesAccBtn = null;
  let otherAccBtn = null;
  const {
    state: { categories, pages },
  } = useContext();

  /**
   * Collapse out accordions in desktop
   * and collapse them in mobile
   *
   * @returns updated
   */
  const updateMenuDisplay = () => {
    if (contactAccBtn === null)
      contactAccBtn =
        contactAccRef.current.getElementsByClassName("accordion-button")[0];

    if (categoriesAccBtn === null)
      categoriesAccBtn =
        categoriesAccRef.current.getElementsByClassName("accordion-button")[0];

    if (otherAccBtn === null)
      otherAccBtn =
        otherAccRef.current.getElementsByClassName("accordion-button")[0];

    if ({ height, width }.width <= 991 && !breakpointUpdated) {
      // contact accordion always open
      if (contactAccBtn.classList.contains("collapsed")) {
        contactAccBtn.click();
      }
      // categories and other accordion always collapsed
      if (!categoriesAccBtn.classList.contains("collapsed")) {
        categoriesAccBtn.click();
      }
      if (!otherAccBtn.classList.contains("collapsed")) {
        otherAccBtn.click();
      }
      return setBreakpointUpdated(true);
    }

    if ({ height, width }.width > 991 && breakpointUpdated) {
      // all accordions always open
      if (contactAccBtn.classList.contains("collapsed")) {
        contactAccBtn.click();
      }
      // categories and other accordion always collapsed
      if (categoriesAccBtn.classList.contains("collapsed")) {
        categoriesAccBtn.click();
      }
      if (otherAccBtn.classList.contains("collapsed")) {
        otherAccBtn.click();
      }
      return setBreakpointUpdated(false);
    }
  };

  useEffect(() => {
    updateMenuDisplay();
  });

  return (
    <footer id="footer">
      <div className="footer-content">
        <div className="footer-center">
          <Container>
            <Row>
              <Col lg={4} md={12}>
                <Accordion
                  ref={contactAccRef}
                  defaultActiveKey="0"
                  className="footer_contact"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h3>Contacto</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <ul>
                          <li>
                            <Link to="/">
                              <Image
                                src={logoFooter}
                                className="img-responsive"
                              />
                            </Link>
                            <p>Somos una empresa orgullosamente mexicana.</p>
                          </li>
                          <li className="single-line">
                            <i className="fa fa-home me-2"></i>
                            <p>
                              Poniente 27A No.4005 Col. Del Gas. Alc.
                              Azcapotzalco, CDMX, C.P. 02950
                            </p>
                          </li>
                          <li className="single-line">
                            <i className="fa fa-envelope-o me-2"></i>
                            <p>
                              <a
                                href="mailto:ventas@univerplast.com.mx"
                                style={{ display: "unset" }}
                              >
                                ventas@univerplast.com.mx
                              </a>
                            </p>
                          </li>
                          <li className="single-line">
                            <i className="fa fa-phone me-2"></i>
                            <p>
                              <a
                                href="tel:5555560799"
                                style={{ display: "unset" }}
                              >
                                (55) 5556 0799
                              </a>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col lg={4} md={12}>
                <Accordion ref={categoriesAccRef} defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h3>Categorías</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {categories.map(({ id, name, link }) => (
                          <li key={id}>
                            <NavLink to={link} className="link">
                              {name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col lg={4} md={12}>
                <Accordion ref={otherAccRef} defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h3>Univerplast</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {pages.map(({ id, name, link }) => (
                          <li key={id}>
                            <NavLink to={link} className="link">
                              {name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer-after">
          <Container>
            <Row>
              <div className="ft_bottom">
                <Col md={12} className="copyright_left">
                  <p>
                    Copyright ©{new Date().getUTCFullYear()} Grupo UNIVERPLAST
                    México
                  </p>
                </Col>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
