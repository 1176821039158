import PropTypes from "prop-types";
import "./styles.css";

const WhatsAppBubble = ({ phone, message }) => {
  return (
    <a
      className="pulse-button"
      href={`https://api.whatsapp.com/send?phone=${phone}&text=${message}`}
      target="_blank"
      rel="noreferrer"
    >
      <img
        alt="wa"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAEeUlEQVRoge2ZTWwVVRiG3+G2FEpiKdJKQEiNKGhI8AeU4G8pbkxMWLgwsvAnakyMUVSsP9FoFJFqjDFGCSa6MuLGDSJhQSAqSRMlqCS1EomJtkCpFSvYQmv7uJhBh2/O3Htm7hQX9k3uYma+733f78yZmfOdK01iEv9vBEWQAFMkLZfUKmmZpEWS5kmaEWmclHRY0kFJ+yTtkdQZBMFYEfq5AcwFNgA/kx29wGvAgv/C+CzgbeBUDuMWI8AWoOlcmb8dOFaAcYvfgLUTabwEvFXGwAlgK/AAcA3QBNRGvyZgOXAf8CEwWIbnPaC2aPN1wCcpgj9Gpmdk4JsO3AN0p3DuyMJXSayUYv408DQwtQruGuAxYCiliOrvBPCmg/wYcH3V5P9qLAEOOnS2VEu8Bhg3pF1AS0He41pNwD5HEXfmJWwAjjhG/qKCvcc1mxx3YgCYnYfsDUN0GrjREbcQaKOg93g0newzsTkrSbODZIMjrh0Yi673UtCbA3jcMXjzsxA8bwj6gQYTcwvJ5+P+ggqoBX4w3B2+yQHhuz2OdY64PSSxv4gCIv57DXcP4aKxYuJSkzjkGP0aYNRRAMDKggqoB/4w3CtsnKuiVnO8MwiCQXNutqSaFO27c/hNIAiCIUnbzembbZyrgKvN8W5HTL+kUynaByq684fVXmYDXAUsMsff2oCoEfnakbtd0ju+7jxgtS+pmEGyOXE2HMBdjvk/rwjXMY1mw9/vk3TcJM1MiasB9pvYxLeiygLqDH/atD0r6VeTdH6Z2BXAX7HYUYpd5E0zXoZ9kuw34OIK8a+Y+KPApQUVMMdw9/kkdZqkVRXiS8BOk9MDXJUSfwXwAh7NPHCd4a38oSRs6eJY75HTSPLTPww8DJRicXOAX6LrI8AHwOIyvA8Zzq0+BTxoknZUTArz5gOHSOI74A7CpfKXjutjwEfANAen7QSf8DGy2CSNUOZBNrlzga8cJn1woeE6j+SK+EofH4pGLY6K0yiWWw+8S3KlWg4DQGB4HjUxh2xMORNPmeQ+oN63iIijFdjrWcBGk9tAuISP48Us4o3A74bguSwFxLhuINx5G3AYHyHcmiyZnI0m7k/ggqzCLxmSo3kKiPFNjYppB14F1uHor6M7N2K0X88jaOfgtmoK8NRsIblteQTTj/iSfWqIHpkAz3G9hSS/JePAbXnIphLudcZx+QT4PqPXhvsZ2ZSX8CZD1BO7tgBYS/iqbAemV2G8EejA3Z5+jE8PnEL8siH7nnBX2fVHxuGokFkZja9PGXUId7m99lydHwagU9K1voYijCpsAXdJ+kbST5KOSxqT1CipRdJSSW2SVkuqc0lL6pD0TBAE4xn1/zHfyNlr/HOFHuDWrH5dc2yVpJLj/BkMS/pc0vuSTmQVdOCkpE2SLguC4LOq2YDNZmQGgG3Ak8BKYnMTmEm47DiQY8S7gWfJs3EbQ+IZAHZL6pW0V9IXkrp85iNhF7Za4TxfIqlZ4dyfImlQUp+kboV/s+4KgqCrGuOTmMQkQvwNNDHIs6b0NQwAAAAASUVORK5CYII="
      />
    </a>
  );
};

WhatsAppBubble.propTypes = {
  phone: PropTypes.string,
  message: PropTypes.string,
};

WhatsAppBubble.defaultProps = {
  phone: "",
  message: "Hola, necesito más informes.",
};

export default WhatsAppBubble;
