import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import Api from "../Api";
import axios from "axios";
import { useCart } from "react-use-cart";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { Check } from "lucide-react";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";
import PhoneNumberInput from "../components/PhoneNumberInput";
import headerImg from "./../assets/img/cart/header.jpg";

// async axios request
let request;
const Checkout = ({ title, crumbs }) => {
  let navigate = useNavigate();
  PageTitle(title);

  // Translation
  const { t } = useTranslation(["form"]);

  // Cart
  const { isEmpty, items, emptyCart } = useCart();
  const [quotationSent, setQuotationSent] = useState(false);

  /**
   * Validation
   */
  const [isProcessing, setIsProcessing] = useState(false);
  const messageLength = 2000;
  const [textAreaWrited, setTextAreaWrited] = useState(0);
  const [personName, setPersonName] = useState("");
  const validationSchema = yup.object({
    first_name: yup
      .string()
      .required(t("form:error.required", { field: t("form:field.first_name") }))
      .max(
        63,
        t("form:error.max", {
          field: t("form:field.message"),
          length: 63,
        })
      ),
    last_name: yup
      .string()
      .required(t("form:error.required", { field: t("form:field.last_name") }))
      .max(
        63,
        t("form:error.max", {
          field: t("form:field.message"),
          length: 63,
        })
      ),
    phone: yup
      .string()
      .required(
        t("form:error.required", { field: t("form:field.phonecountry") })
      )
      .test(
        "test-phone",
        t("form:error.regex", { field: t("form:field.phonecountry") }),
        function (v, e) {
          return v && isPossiblePhoneNumber(v) ? true : false;
        }
      ),
    email: yup
      .string()
      .email(t("form:error.required", { field: t("form:field.email") }))
      .required(t("form:error.required", { field: t("form:field.email") })),
    notes: yup.string().max(
      messageLength,
      t("form:error.max", {
        field: t("form:field.message"),
        length: messageLength,
      })
    ),
    address_line_1: yup.string().max(
      250,
      t("form:error.max", {
        field: t("form:field.message"),
        length: 250,
      })
    ),
    address_line_2: yup.string().max(
      250,
      t("form:error.max", {
        field: t("form:field.message"),
        length: 250,
      })
    ),
    city: yup.string().max(
      64,
      t("form:error.max", {
        field: t("form:field.message"),
        length: 64,
      })
    ),
    state: yup.string().max(
      64,
      t("form:error.max", {
        field: t("form:field.message"),
        length: 64,
      })
    ),
    zip_code: yup.string().max(
      15,
      t("form:error.max", {
        field: t("form:field.message"),
        length: 15,
      })
    ),
  });
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip_code: "",
      notes: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsProcessing(true);

      // sanitize inputs
      var purified = { ...values };
      purified["country_code"] = parsePhoneNumber(purified["phone"]).country;
      purified["products"] = items.map((item) => ({
        product_id: item.id.split("_")[0],
        variation_id: item.id.split("_")[1],
        quantity: item.quantity,
      }));
      Object.keys(purified).forEach((k) => {
        values[k] = DOMPurify.sanitize(("" + values[k]).trim(), {
          USE_PROFILES: { html: false },
        });
      });

      // cancel previous request
      if (request) request.cancel();

      // create a new cancel token
      request = axios.CancelToken.source();

      Api.post("quotations", purified, {
        cancelToken: request.token,
      })
        .then((res) => {
          // reset
          setPersonName(formik.values.first_name);
          setQuotationSent(true);
          formik.resetForm();
          setTextAreaWrited(0);
          emptyCart();
        })
        .catch((error) => {})
        .finally(() => {
          setIsProcessing(false);
        });
    },
  });

  const trimToLength = (value, length = 5) => {
    return value.substr(0, length);
  };

  useEffect(() => {
    // Products still not added to the cart,
    // go back to home
    if (isEmpty && !quotationSent) {
      navigate("/");
    }
  });

  if (isEmpty && !quotationSent) {
    // Products still not added to the cart,
    // render empty
    return <></>;
  } else if (!isEmpty && !quotationSent) {
    // Fill data
    return (
      <section id="wrapper" className="active_grid">
        <Breadcrumbs photo={headerImg} crumbs={crumbs} />
        <Container>
          <div id="content-wrapper">
            <section id="main">
              <Row className="mb-5">
                <Col lg={8} md={12}>
                  <Card className="cart-item-container mb-4">
                    <Card.Body>
                      <h5>Datos del cliente *</h5>
                      <p>
                        Completa el formulario de contacto para recibir tu
                        cotización.
                      </p>
                      {/* Info */}
                      <Form onSubmit={formik.handleSubmit}>
                        <fieldset disabled={isProcessing}>
                          <Row className="mb-2">
                            <Form.Group as={Col} md="6" className="input-col">
                              <FloatingLabel
                                controlId="floatingFirstName"
                                label={t("form:required.first_name")}
                              >
                                <Form.Control
                                  type="text"
                                  name="first_name"
                                  value={formik.values.first_name}
                                  placeholder="Ingresa tus datos"
                                  onChange={(e) => {
                                    e.currentTarget.value = trimToLength(
                                      e.currentTarget.value,
                                      30
                                    );

                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.first_name &&
                                    formik.errors.first_name
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.first_name &&
                                formik.errors.first_name ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.first_name}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="input-col">
                              <FloatingLabel
                                controlId="floatingLastName"
                                label={t("form:required.last_name")}
                              >
                                <Form.Control
                                  type="text"
                                  name="last_name"
                                  value={formik.values.last_name}
                                  placeholder="Ingresa tus datos"
                                  onChange={(e) => {
                                    e.currentTarget.value = trimToLength(
                                      e.currentTarget.value,
                                      30
                                    );

                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.last_name &&
                                    formik.errors.last_name
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.last_name &&
                                formik.errors.last_name ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.last_name}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="input-col">
                              <FloatingLabel
                                controlId="floatingEmail"
                                label={t("form:required.email")}
                              >
                                <Form.Control
                                  type="email"
                                  name="email"
                                  value={formik.values.email}
                                  placeholder="Ingresa tus datos"
                                  onChange={(e) => {
                                    e.currentTarget.value = trimToLength(
                                      e.currentTarget.value,
                                      100
                                    );
                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.email && formik.errors.email
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.email && formik.errors.email ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.email}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="input-col">
                              <PhoneInput
                                name="phone"
                                labels={es}
                                defaultCountry="MX"
                                international
                                addInternationalOption={false}
                                countryCallingCodeEditable={false}
                                placeholder="XXX XXX XXXX"
                                value={formik.values.phone}
                                label={`${t("form:required.phonecountry")}`}
                                errors={formik.errors.phone}
                                className={
                                  formik.errors.phone ? "is-invalid" : null
                                }
                                inputComponent={PhoneNumberInput}
                                onChange={(e) =>
                                  formik.setFieldValue("phone", e)
                                }
                              />
                              {formik.touched.phone && formik.errors.phone ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.phone}
                                </Form.Control.Feedback>
                              ) : null}
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="input-col">
                              <Form.Text className="text-muted">
                                Información adicional a tu cotización (Opcional)
                              </Form.Text>
                              <Form.Control
                                as="textarea"
                                name="notes"
                                style={{
                                  height: "150px",
                                  resize: "none",
                                }}
                                placeholder=""
                                value={formik.values.notes}
                                onChange={(e) => {
                                  setTextAreaWrited(
                                    e.currentTarget.value.length
                                  );
                                  formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.touched.notes && formik.errors.notes
                                    ? "is-invalid"
                                    : null
                                }
                              />
                              {/* Left character count */}
                              <Form.Text
                                className={`${
                                  textAreaWrited >= messageLength
                                    ? "text-danger"
                                    : textAreaWrited >=
                                      messageLength -
                                        Math.round(messageLength * 0.07)
                                    ? "text-warning"
                                    : "text-success"
                                }`}
                              >
                                {messageLength - textAreaWrited}
                              </Form.Text>
                              {formik.touched.notes && formik.errors.notes ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.notes}
                                </Form.Control.Feedback>
                              ) : null}
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="input-col">
                              <p className="text-primary mb-0">
                                <i className="fa fa-info-circle mr-1"></i>{" "}
                                Recuerda que puedes visitar en cualquier momento
                                nuestras{" "}
                                <a href="/privacidad" target="_blank">
                                  políticas de privacidad.
                                </a>
                              </p>
                              <hr />
                              <h5>Dirección de entrega</h5>
                              <p>
                                Los sigientes campos son opcionales. Sin embargo
                                entre más precisa sea tu información, nos ayudas
                                a mejorar los costos de tu cotización.
                              </p>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="12"
                              md="12"
                              sm="12"
                              className="input-col"
                            >
                              <FloatingLabel
                                controlId="floatingAddressLine1"
                                label={t("form:label.address_line_1")}
                              >
                                <Form.Control
                                  type="text"
                                  name="address_line_1"
                                  value={formik.values.address_line_1}
                                  placeholder="Ingresa tus datos"
                                  onChange={(e) => {
                                    e.currentTarget.value = trimToLength(
                                      e.currentTarget.value,
                                      255
                                    );
                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.address_line_1 &&
                                    formik.errors.address_line_1
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.address_line_1 &&
                                formik.errors.address_line_1 ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.address_line_1}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="12"
                              md="12"
                              sm="12"
                              className="input-col"
                            >
                              <FloatingLabel
                                controlId="floatingAddressLine2"
                                label={t("form:label.address_line_2")}
                              >
                                <Form.Control
                                  type="text"
                                  name="address_line_2"
                                  value={formik.values.address_line_2}
                                  placeholder="Ingresa tus datos"
                                  onChange={(e) => {
                                    e.currentTarget.value = trimToLength(
                                      e.currentTarget.value,
                                      255
                                    );
                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.address_line_2 &&
                                    formik.errors.address_line_2
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.address_line_2 &&
                                formik.errors.address_line_2 ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.address_line_2}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              md="6"
                              sm="12"
                              className="input-col"
                            >
                              <FloatingLabel
                                controlId="floatingCity"
                                label={t("form:label.city")}
                              >
                                <Form.Control
                                  type="text"
                                  name="city"
                                  value={formik.values.city}
                                  placeholder="Ingresa tus datos"
                                  onChange={(e) => {
                                    e.currentTarget.value = trimToLength(
                                      e.currentTarget.value,
                                      64
                                    );

                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.city && formik.errors.city
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.city && formik.errors.city ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.city}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              md="6"
                              sm="12"
                              className="input-col"
                            >
                              <FloatingLabel
                                controlId="floatingState"
                                label={t("form:label.state")}
                              >
                                <Form.Control
                                  type="text"
                                  name="state"
                                  value={formik.values.state}
                                  placeholder="Ingresa tus datos"
                                  onChange={(e) => {
                                    e.currentTarget.value = trimToLength(
                                      e.currentTarget.value,
                                      64
                                    );

                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.state && formik.errors.state
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.state && formik.errors.state ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.state}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              md="6"
                              sm="12"
                              className="input-col"
                            >
                              <FloatingLabel
                                controlId="floatingZip"
                                label={t("form:label.zip_code")}
                              >
                                <Form.Control
                                  type="text"
                                  name="zip_code"
                                  value={formik.values.zip_code}
                                  placeholder="Ingresa tus datos"
                                  onChange={(e) => {
                                    e.currentTarget.value = trimToLength(
                                      e.currentTarget.value,
                                      15
                                    );

                                    formik.handleChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.zip_code &&
                                    formik.errors.zip_code
                                      ? "is-invalid"
                                      : null
                                  }
                                />
                                {formik.touched.zip_code &&
                                formik.errors.zip_code ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.zip_code}
                                  </Form.Control.Feedback>
                                ) : null}
                              </FloatingLabel>
                            </Form.Group>
                          </Row>
                        </fieldset>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12}>
                  <Card className="cart-info-panel mb-4">
                    <Card.Body>
                      <h5 className="mb-3">Finaliza tu cotización</h5>
                      <Button
                        variant="primary"
                        disabled={isProcessing}
                        onClick={() => {
                          formik.submitForm();
                        }}
                      >
                        <i className="fa fa-check me-1" />
                        {!isProcessing
                          ? t("form:submit.send")
                          : t("form:submit.sending")}
                      </Button>
                    </Card.Body>
                  </Card>
                  <Card className="cart-warranty mb-4">
                    <Card.Body>
                      <h5 className="mb-4">Garantía</h5>
                      <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex h6 px-0">
                          <i className="fa fa-file me-2"></i>Te enviamos tu
                          cotización vía telefónica y correo electrónico.
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex h6 px-0">
                          <i className="fa fa-lock me-2"></i>Protegemos tus
                          datos con cifrado y certificado TLS.
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex h6 px-0">
                          <i className="fa fa-address-card me-2"></i>Para
                          brindarte el servicio que mereces, la veracidad de tu
                          información es muy importante para nosotros.
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </section>
          </div>
        </Container>
      </section>
    );
  } else {
    // Products sent
    return (
      <section id="wrapper" className="active_grid">
        <Breadcrumbs photo={headerImg} crumbs={crumbs} />
        <Container>
          <div id="content-wrapper">
            <section id="main">
              <Row className="mb-5">
                <Col lg={8} md={12}>
                  <Card className="cart-empty text-center">
                    <Card.Body>
                      <h3 className="text-center">Gracias {personName}</h3>
                      <Card.Text className="text-center">
                        <div className="text-center">
                          <div
                            className="icon d-flex align-items-center justify-content-center bg-soft-success rounded-circle mx-auto"
                            style={{ height: "95px", width: "95px" }}
                          >
                            <h1 className="mb-0">
                              <Check size={48} className="align-middle" />
                            </h1>
                          </div>
                          <div className="p-3 text-center">
                            <h4>Recibimos tus productos</h4>
                            <p className="text-muted text-center">
                              Nuestro equipo de venta se pondrá en contacto
                              contigo a la brevedad.
                            </p>
                          </div>
                        </div>
                      </Card.Text>
                      <Link to="/">
                        <Button variant="primary">Volver al home</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12}>
                  <Card className="cart-warranty mb-4">
                    <Card.Body>
                      <h5 className="mb-4">Garantía</h5>
                      <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex h6 px-0">
                          <i className="fa fa-file me-2"></i>Te enviamos tu
                          cotización vía telefónica y correo electrónico.
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex h6 px-0">
                          <i className="fa fa-lock me-2"></i>Protegemos tus
                          datos con cifrado y certificado TLS.
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex h6 px-0">
                          <i className="fa fa-address-card me-2"></i>Para
                          brindarte el servicio que mereces, la veracidad de tu
                          información es muy importante para nosotros.
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </section>
          </div>
        </Container>
      </section>
    );
  }
};

export default Checkout;
