import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "./../../AppContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import logoImg from "./../../assets/img/logo.svg";
import selloImg from "./../../assets/img/hecho_en_mexico.png";
import logoHorizontalImg from "./../../assets/img/logo_horizontal.svg";
import InputSearch from "./../InputSearch";
import CartButton from "../CartButton";
import { Menu } from "lucide-react";
import Treeview from "../Treeview/Treeview";

const Header = () => {
  const {
    state: { categories, pages },
  } = useContext();
  const [mobileMenuMade, setMobileMenuMade] = useState(false);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [pagesTree, setPagesTree] = useState([]);

  const mobileMenuMaker = () => {
    setMobileMenuMade(true);

    const formattedCategories = [];
    categories.forEach((element) => {
      const mainCategory = { ...element };
      delete mainCategory.childs;
      formattedCategories.push(mainCategory);
    });

    formattedCategories.push({
      id: "categories",
      name: "Ver todas",
      link: "#",
      parent_id: null,
      childs: categories,
    });

    setCategoriesTree(formattedCategories);
    setPagesTree(pages);
  };

  useEffect(() => {
    if (!mobileMenuMade && pages.length > 0 && categories.length > 0) {
      mobileMenuMaker();
    }
    if (mobileMenuMade) {
      new window.hcOffcanvasNav("#main-nav", {
        navTitle: "Menú",
        levelTitles: true,
        levelTitleAsBack: true,
        customToggle: ".toggle",
      });
    }
  });

  return (
    <header id="header">
      <div className="header-before">
        <Container>
          <Row>
            <div className="hd_header">
              <Col md={6} sm={6} className="header-item">
                <p className="text-left">
                  <a href="mailto:ventas@univerplast.com.mx">
                    ventas@univerplast.com.mx
                  </a>
                </p>
              </Col>
              <Col md={6} sm={6} className="header-item">
                <p className="text-right">
                  <a href="tel:5555560799">(55) 5556 0799</a>
                </p>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
      <div className="desktop-menu">
        {/* Header */}
        <nav className="header-nav">
          <Row>
            <Col
              md={4}
              sm={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Col lg={10}>
                <InputSearch />
              </Col>
            </Col>
            <Col
              md={4}
              sm={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Link to="/">
                <Image src={logoImg} width="180px" height="fit-content" />
              </Link>
            </Col>
            <Col
              md={4}
              sm={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Link to="/empresa">
                <Image src={selloImg} width="80px" height="fit-content" />
              </Link>
            </Col>
          </Row>
        </nav>
        {/* Navbar */}
        <div id="header-sticky" className="header-top">
          <div className="position-static" id="header-menu">
            <div className="container mw-100">
              <div className="row">
                <div id="_desktop_fieldmegamenu" className="fieldmegamenu">
                  <ul>
                    <li className="root">
                      <div className="root-item no-description pull-left">
                        <Link to="/">
                          <div className="title title_font">
                            <span className="title-text">Home</span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li className="root">
                      <div className="root-item no-description pull-left">
                        <div className="title title_font">
                          <span className="title-text">Nosotros</span>
                        </div>
                      </div>
                      <ul
                        id="submegamenu-1"
                        className="menu-items col-md-3 col-xs-12 collapse"
                      >
                        <li className="menu-item menu-item-0 depth-1 category menucol-1-1 mb-0">
                          <ul
                            id="submenu-0"
                            className="submenu submenu-depth-2 collapse"
                          >
                            <li className="menu-item depth-2 category">
                              <div className="title">
                                <Link to="/empresa">Corporativo</Link>
                              </div>
                            </li>
                            <li className="menu-item depth-2 category">
                              <div className="title">
                                <Link to="/clientes">Clientes</Link>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="root">
                      <div className="root-item no-description pull-left">
                        <Link to="/categorias">
                          <div className="title title_font">
                            <span className="title-text">Productos</span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li className="root">
                      <div className="root-item no-description pull-left">
                        <Link to="/desarrollo-de-producto">
                          <div className="title title_font">
                            <span className="title-text">
                              Desarrollo de producto
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li className="root">
                      <div className="root-item no-description pull-left">
                        <Link to="/catalogos">
                          <div className="title title_font">
                            <span className="title-text">Catálogos</span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li className="root">
                      <div className="root-item no-description pull-left">
                        <Link to="/contacto">
                          <div className="title title_font">
                            <span className="title-text">Contacto</span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li className="root">
                      <div className="root-item no-description pull-left ms-3">
                        <CartButton />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <div className="mobile-menu">
        {/* Header */}
        <Container>
          <Row className="pt-2 pb-2">
            <Col className="text-center icon">
              <Menu className="toggle" />
            </Col>
            <Col xs={8} className="d-flex text-center p-0">
              <Link to="/">
                <Image src={logoHorizontalImg} className="logo" />
              </Link>
            </Col>
            <Col className="text-center icon">
              <CartButton />
            </Col>
          </Row>
          <Row>
            <InputSearch />
          </Row>
        </Container>
        {/* Navbar */}
        <nav id="main-nav" className="d-none">
          <ul>
            <li>
              <Link to="/" className="link">
                Home
              </Link>
            </li>
          </ul>
          <h3 className="mb-0">Categorías principales</h3>
          <Treeview tree={categoriesTree} />
          <h3 className="mb-0">Univerplast</h3>
          <Treeview tree={pagesTree} />
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
