import TreeNode from "./TreeNode";

/**
 * Creates a <ul><li> tree
 * @param {array} tree
 * @returns
 */
const Treeview = ({ tree }) => {
  var nodes = tree.map((i) => (
    <TreeNode key={i.id} node={i} childs={i.childs} />
  ));

  return <ul>{nodes}</ul>;
};

export default Treeview;
