import { useEffect, useState, useCallback } from "react";
import PageTitle from "../components/hooks/PageTitle";
import { useLocation } from "react-router-dom";
import { useCart } from "react-use-cart";
import Api from "../Api";
import Breadcrumbs from "../components/Breadcrumbs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Select, { components } from "react-select";
import { store } from "react-notifications-component";
import ImageGallery from "react-image-gallery";
import InputQuantity from "../components/InputQuantity";
import WindowDimensions from "./../components/hooks/WindowDimensions";
import placeholderImg from "../assets/img/products/placeholder.jpg";
import "react-image-gallery/styles/css/image-gallery.css";
import headerImg from "./../assets/img/products/header.jpg";

const Item = ({ title, crumbs }) => {
  let location = useLocation();
  const [isLoading, setLoading] = useState(true);
  // cart
  const { addItem } = useCart();
  // product
  const [product, setProduct] = useState([]);
  // gallery
  const [gallery, setGallery] = useState([
    {
      original: placeholderImg,
      thumbnail: placeholderImg,
    },
  ]);
  // gallery responsive
  const { height, width } = WindowDimensions();
  const [breakpointUpdated, setBreakpointUpdated] = useState(false);
  const [thumbPos, setThumbPos] = useState("left");
  // variant displayed
  const [currentVariant, setCurrentVariant] = useState({});
  // variants used in react-select
  const [variantCombinations, setVariantCombinations] = useState([]);
  // current variants selected in react-select
  const [selectedVariant, setSelectedVariant] = useState([]);
  // Quantity control
  const [quantity, setQuantity] = useState(1);
  // form errors
  const [errors, setErrors] = useState([]);

  PageTitle(product.title ? product.title : title);

  /**
   * Init page
   * @param {json} productData
   */
  const initPage = useCallback((productData) => {
    // 1. get variants to fill react-selects
    const nodeVariations = productData.variations;
    var combinations = [];

    nodeVariations.forEach((node) => {
      node.attribute_combinations.forEach((combination) => {
        // create combination object
        const obj = {
          id: combination.id,
          name: combination.name,
          values: [],
        };
        // get the current combination object
        let currentCombination = combinations.find((el) => el.id === obj.id);

        // add if not exists
        if (!currentCombination) {
          combinations.push(obj);
          currentCombination = obj;
        }

        // create value object
        const val = {
          value: combination.value_name,
          label: combination.value_name,
          available: true,
        };

        // add if not exists
        if (!currentCombination.values.some((el) => el.label === val.label)) {
          currentCombination.values.push(val);
        }
      });
    });

    // sort values alphabetically
    combinations.forEach((item) => {
      item.values.sort((a, b) => (a.label > b.label ? 1 : -1));
    });
    // console.log("Combinations: ", combinations);

    // 2. Get the default variant (first)
    let defaultVariant = [];
    if (nodeVariations.length >= 1) {
      nodeVariations[0].attribute_combinations.forEach((element) => {
        defaultVariant.push({
          id: element.id,
          label: element.value_name,
          value: element.value_name,
          available: true,
        });
      });
    }
    // console.log("Default: ", defaultVariant);

    // 3. Select default variant
    // invalidate all options
    invalidateAllCombinations(combinations);

    // check valid combinations
    const validCombinations = calculateValidCombinations(
      defaultVariant,
      productData
    );
    // console.log("Valid: ", validCombinations);
    applyValidCombinations(combinations, validCombinations);

    // 4. Get variant
    const filteredVariant = getVariant(defaultVariant, productData);
    // console.log("Variant filtered: ", filteredVariant);

    // 5. Update states
    setCurrentVariant(filteredVariant);
    updateGallery(productData, filteredVariant.picture_ids);
    setVariantCombinations(combinations);
    setSelectedVariant(defaultVariant);
  }, []);

  /**
   * Get all posible combinations with
   * a given array of variants
   * @param {object} variant
   * @param {object} productData
   */
  const calculateValidCombinations = (variant, productData) => {
    let allVariants = [];
    let validCombinations = [];
    // store all variants into a single array
    productData.variations.forEach((variation) => {
      allVariants.push(variation.attribute_combinations);
    });
    // find all posible variants with selected filters
    variant.forEach((selected) => {
      allVariants.forEach((variant) => {
        const valid = variant.some(
          (x) => x.id === selected.id && x.value_name === selected.value
        );
        if (valid) {
          variant.forEach((item) => {
            if (
              !validCombinations.find(
                (x) => x.id === item.id && x.value_name === item.value_name
              )
            )
              validCombinations.push(item);
          });
        }
      });
    });
    return validCombinations;
  };

  /**
   * Sets the requested combinations as available
   * in a valid combinations object
   * @param {object} combinations
   * @param {object} validCombinations
   */
  const applyValidCombinations = (combinations, validCombinations) => {
    validCombinations.forEach((valid) => {
      combinations.forEach((combination) => {
        if (combinations.length > 1) {
          if (valid.id === combination.id) {
            const found = combination.values.find(
              (x) => x.value === valid.value_name
            );
            if (found) {
              found.available = true;
            }
          }
        } else {
          // if only 1 combination exists (i.e. only color)
          // set all options as available
          combination.values.map((x) => (x.available = true));
        }
      });
    });
  };

  /**
   * Set all combinations as not available
   * @param {object} combinations
   */
  const invalidateAllCombinations = (combinations) => {
    combinations.forEach((combination) => {
      combination.values.forEach((value) => {
        value.available = false;
      });
    });
  };

  /**
   * Returns a variant in a product with
   * a given combination
   * @param {object} combinations
   * @param {object} productData
   */
  const getVariant = (combinations, productData) => {
    let searchFormat1 = [];
    combinations.forEach((term) => {
      searchFormat1.push({ id: term.id, value: term.value });
    });
    let filteredVariant = [];
    productData.variations.some((variant) => {
      let searchFormat2 = [];
      variant.attribute_combinations.forEach((attribute) => {
        searchFormat2.push({ id: attribute.id, value: attribute.value_name });
      });
      const result = searchFormat1.filter(
        ({ value: id1 }) => !searchFormat2.some(({ value: id2 }) => id2 === id1)
      );

      if (result.length === 0) {
        filteredVariant = variant;
        return true;
      }

      return false;
    });
    return filteredVariant;
  };

  /**
   * Update image gallery.
   * @param {array} ids
   */
  const updateGallery = (productData, ids) => {
    const pictures = [];
    if (ids && ids.length > 0) {
      // use gallery
      productData.pictures.forEach((pic) => {
        if (ids.includes(pic.id)) {
          pictures.push({ original: pic.url, thumbnail: pic.url });
        }
      });
    } else if (productData.thumbnail) {
      // try to use thumbnail if no ids provided
      pictures.push({
        original: productData.thumbnail,
        thumbnail: productData.thumbnail,
      });
    } else {
      // load placeholder
      pictures.push({
        original: placeholderImg,
        thumbnail: placeholderImg,
      });
    }

    setGallery(pictures);
  };

  /**
   * Change the thumbnails position
   *
   * @returns updated
   */
  const updateGalleryThumPosition = useCallback(() => {
    if ({ height, width }.width <= 991 && !breakpointUpdated) {
      setThumbPos("bottom");
      return setBreakpointUpdated(true);
    }

    if ({ height, width }.width > 991 && breakpointUpdated) {
      setThumbPos("left");
      return setBreakpointUpdated(false);
    }
  }, [breakpointUpdated, height, width]);

  /**
   * On variant select
   * @param {object} val
   * @param {object} obj
   */
  const onVariantChange = (val, obj) => {
    // 1. format selected value
    const selectedField = {
      id: obj.name,
      label: val.label,
      value: val.value,
      available: val.available,
    };

    // 2. field form validation
    let validatedErrors = [...errors];
    const filteredItems = validatedErrors.filter(
      (item) => item !== selectedField.id
    );
    setErrors(filteredItems);

    // 3. upsert existing filters
    let upsertVariant = [...selectedVariant];

    if (!selectedField.available) {
      // reset all filters
      upsertVariant = [];
      // set the current field as available and keep
      selectedField.available = true;
      upsertVariant.push(selectedField);
    } else {
      // upsert variant
      const foundIndex = upsertVariant.findIndex(
        (x) => x.id === selectedField.id
      );
      foundIndex >= 0
        ? (upsertVariant[foundIndex] = selectedField)
        : upsertVariant.push(selectedField);
    }

    // 4. select variant
    let newVariantCombinations = [...variantCombinations];
    invalidateAllCombinations(newVariantCombinations);

    const validCombinations = calculateValidCombinations(
      upsertVariant,
      product
    );
    applyValidCombinations(newVariantCombinations, validCombinations);

    const filteredVariant = getVariant(upsertVariant, product);

    // 5. Update states
    setCurrentVariant(filteredVariant);
    updateGallery(product, filteredVariant.picture_ids);
    setVariantCombinations(newVariantCombinations);
    setSelectedVariant(upsertVariant);
  };

  /**
   * On input quantity changed
   */
  const onQuantityChanged = (quantity) => {
    setQuantity(quantity.quantity);
  };

  /**
   * Go to previous page
   */
  const goBack = () => {
    const origin = window.location.origin;
    if (document.referrer.indexOf(origin) >= 0) {
      window.history.back();
    } else {
      window.location.href = origin; // this might just be '/' of your site
    }
  };

  const formatItemForCart = () => {
    // composite id
    let id = `${product.id}`;
    let variant = [];
    if (Object.keys(currentVariant).length > 0) {
      id = `${product.id}_${currentVariant.id}`;
      variant = currentVariant.attribute_combinations;
    }
    const item = {
      id: id,
      title: product.title,
      variant: variant,
      permalink: product.permalink,
      thumbnail: product.thumbnail,
      price: 0,
    };
    return item;
  };

  /**
   * Add product to cart
   */
  const addToCart = () => {
    // form validation only if product have more than 1 variant
    let validatedErrors = [];
    if (product.variations.length > 1) {
      if (selectedVariant.length !== variantCombinations.length) {
        variantCombinations.forEach((variant) => {
          const itm = selectedVariant.find((x) => x.id === variant.id);
          return !itm ? validatedErrors.push(variant.id) : null;
        });
      }
      setErrors(validatedErrors);
    }

    if (validatedErrors.length === 0) {
      const plural = quantity > 1 ? "es" : "";
      const plural2 = quantity > 1 ? "s" : "";

      // format cart item
      addItem(formatItemForCart(), quantity);
      store.addNotification({
        title: "Carrito",
        message: quantity + " unidad" + plural + " agregada" + plural2,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2500,
          onScreen: true,
        },
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    // url
    const productSegment = location.pathname.split("/").pop();
    let productID = productSegment.split("-", 1).toString();
    productID = isNaN(productID) ? 0 : productID;

    Api.get(`products/${productID}`)
      .then((res) => {
        if (isMounted)
          setProduct(() => {
            initPage(res.data);
            return res.data;
          });
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });

    updateGalleryThumPosition();

    return () => {
      isMounted = false;
    };
  }, [location, initPage, updateGalleryThumPosition]);

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <section id="content" className="page-content page-cms page-cms-4">
              <div className="block">
                <div className="cms-row block block_top clearfix">
                  {/* Preloader */}
                  {isLoading ? (
                    <Row>
                      <Col lg={6} md={6} xs={12}>
                        <svg
                          className="mb-3"
                          style={{ width: "100%", height: "250px" }}
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="100%"
                            height="100%"
                            fill="#868e96"
                          />
                        </svg>
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        <Placeholder as={Card.Title} animation="glow">
                          <Placeholder xs={8} />
                        </Placeholder>
                        <Placeholder as={Card.Text} animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                        <Placeholder.Button variant="primary" xs={3} />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {/* Not Found */}
                  {!isLoading && product.length === 0 ? (
                    <section
                      className="page-content page-not-found"
                      style={{ boxShadow: "none" }}
                    >
                      <h4>Parece que el producto no existe</h4>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="251"
                        height="154"
                        viewBox="0 0 251 154"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            fill="#CCC"
                            d="M18 151h233v2H18v-2zm-18 0h13v2H0v-2z"
                          ></path>
                          <path
                            fill="#DFDFDF"
                            d="M152 133v8.165h-50V133H28v8.165c0 5.52 3.405 11.827 13.647 11.835l168.906-.291C228.179 152.723 226.377 133 226 133h-74z"
                          ></path>
                          <path
                            fill="#F7D032"
                            d="M153.303 133.42l-24.278.011 24.278.018z"
                          ></path>
                          <path
                            fill="#FFF"
                            d="M58.513 132.71h139.604V34.572l-139.604.397z"
                          ></path>
                          <path
                            fill="#BBB"
                            d="M30 147c.735 2.575 5.553 6.276 13.283 6.276L211 153c6.737 0 12.34-3.372 13-6H30z"
                          ></path>
                          <path
                            stroke="#CCC"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M198 132H59"
                          ></path>
                          <path
                            stroke="#999"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M225 134v7.285c0 4.325-4.247 10.715-13.393 10.715H46.489"
                          ></path>
                          <path
                            stroke="#CCC"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M45 97V28a6 6 0 0 1 6-6h155a6 6 0 0 1 6 6v99"
                          ></path>
                          <path
                            stroke="#CCC"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M198 132.492V37.367c0-1.599-1.397-2.367-3-2.367l-112.35-.11-13.717.094-8.016.016c-1.041 0-1.6.524-1.603 1.567"
                          ></path>
                          <path
                            stroke="#CCC"
                            strokeLinecap="round"
                            strokeWidth="2"
                            d="M113.857 50.642c-7.217 3.53-13.356 9.515-17.051 17.44m-3.464 15.03c-.125 8.971 3.073 17.673 8.959 24.283a34.437 34.437 0 0 0 11.2 8.329c9.825 4.58 20.731 4.139 29.925-.271 7.325-3.513 13.564-9.546 17.302-17.563 2.58-5.533 3.67-11.371 3.436-17.045-.282-6.799-2.468-13.362-6.275-18.882a34.465 34.465 0 0 0-13.857-11.713 34.436 34.436 0 0 0-13.932-3.236c-5.63-.107-11.2 1.16-16.243 3.628"
                          ></path>
                          <g>
                            <path
                              stroke="#CCC"
                              strokeLinecap="square"
                              strokeWidth="2"
                              d="M162.369 44.033l-9.286 10.683"
                            ></path>
                            <path
                              fill="#E6E6E6"
                              d="M177.11 39.194l-4.262 4.902c-2.12 2.44-6.34 2.241-9.423-.438-3.083-2.68-3.866-6.831-1.746-9.27l14.488-16.667 13.418-15.436c2.118-2.436 6.339-2.239 9.422.44 3.082 2.68 3.865 6.833 1.747 9.269"
                            ></path>
                            <path
                              stroke="#CCC"
                              strokeLinecap="round"
                              strokeWidth="2"
                              d="M161.755 35.04c-2.12 2.438-1.657 6.312 1.032 8.649 2.689 2.337 6.589 2.257 8.71-.182l14.487-16.666 13.418-15.436"
                            ></path>
                          </g>
                          <path
                            fill="#CCC"
                            d="M126.321 96.605h4.914v5.136h-4.914v-5.136zm-5.309-28.358c2.009-2.165 4.766-3.247 8.272-3.247 3.243 0 5.84.926 7.79 2.778 1.95 1.852 2.926 4.218 2.926 7.099 0 1.744-.358 3.16-1.074 4.246-.716 1.087-2.16 2.684-4.333 4.79-1.58 1.531-2.605 2.828-3.074 3.89-.47 1.061-.704 2.63-.704 4.703h-4.395c0-2.354.28-4.25.84-5.691.559-1.44 1.785-3.09 3.678-4.95l1.976-1.951a8.174 8.174 0 0 0 1.432-1.754c.658-1.07.987-2.18.987-3.333 0-1.613-.481-3.012-1.444-4.197-.963-1.186-2.556-1.778-4.778-1.778-2.749 0-4.65 1.02-5.704 3.062-.592 1.135-.93 2.773-1.012 4.913H118c0-3.555 1.004-6.415 3.012-8.58z"
                          ></path>
                        </g>
                      </svg>
                      <p className="text-center mt-3">
                        Vuelve a la página anterior
                      </p>
                      <div id="search_widget" className="search-widget">
                        <Button variant="primary" onClick={goBack}>
                          <i className="fa fa-chevron-left"></i>
                          {` Regresar`}
                        </Button>
                      </div>
                    </section>
                  ) : (
                    <></>
                  )}
                  {/* Product */}
                  {Object.entries(product).length > 0 ? (
                    <>
                      <Row>
                        <Col lg={6} md={6} xs={12} className="mb-3">
                          <ImageGallery
                            items={gallery}
                            showNav={false}
                            showFullscreenButton={false}
                            thumbnailPosition={thumbPos}
                            showPlayButton={false}
                            lazyLoad={true}
                            onErrorImageURL={placeholderImg}
                          />
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                          <Form>
                            <h1 className="h4 text-uppercase">
                              {product.title}
                            </h1>
                            <Form.Label className="mt-2">
                              SKU:{" "}
                              {currentVariant.sku
                                ? currentVariant.sku
                                : product.sku
                                ? product.sku
                                : "-"}
                            </Form.Label>
                            <Row className="mb-3">
                              {/* render variations only if product have more than 1 variant */}
                              {product.variations.length > 1 && (
                                <Form.Group as={Col} lg="8" md="12">
                                  {variantCombinations.map((item, idx) => (
                                    <Select
                                      key={idx}
                                      name={item.id}
                                      isSearchable={false}
                                      isClearable={false}
                                      placeholder="Selecciona"
                                      components={{
                                        Control: (props) => (
                                          <div className="mb-2">
                                            {<label>{item.name}</label>}
                                            <components.Control
                                              className={
                                                errors.includes(item.id)
                                                  ? "is-invalid"
                                                  : ""
                                              }
                                              {...props}
                                            />
                                            {errors.includes(item.id) ? (
                                              <Form.Control.Feedback type="invalid">
                                                Elige una variante
                                              </Form.Control.Feedback>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ),
                                      }}
                                      formatOptionLabel={({
                                        label,
                                        available,
                                      }) => (
                                        <div className="d-flex bd-highlight">
                                          <div className="p-2 w-100 bd-highlight">
                                            {label}
                                          </div>
                                          <div
                                            className="p-2 flex-shrink-1 bd-highlight"
                                            style={{
                                              fontSize: "12px",
                                              color: "rgba(0,0,0,.55)",
                                              textAlign: "right",
                                              maxWidth: "110px",
                                              lineHeight: 1.1,
                                            }}
                                          >
                                            {!available
                                              ? "Disponible en otras variantes"
                                              : ""}
                                          </div>
                                        </div>
                                      )}
                                      options={item.values}
                                      onChange={onVariantChange}
                                      value={
                                        // render object or...
                                        selectedVariant.find(
                                          (x) => x.id === item.id
                                        ) || "" // render empty if null
                                      }
                                    />
                                  ))}
                                </Form.Group>
                              )}
                            </Row>
                            <Row className="mb-3">
                              <InputQuantity
                                onQuantityChanged={onQuantityChanged}
                              />
                              <Form.Label className="mt-2">
                                * Agrega la cantidad exacta a tu cotización para
                                brindarte un mejor precio.
                              </Form.Label>
                            </Row>
                            <Button variant="primary" onClick={addToCart}>
                              <i className="fa fa-plus"></i>
                              {` Cotización`}
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Container className="border-top">
                          <Row className="mt-3">
                            <Col>
                              <h4 className="mb-3">Descripción</h4>
                              {product.description ? (
                                <p>{product.description}</p>
                              ) : (
                                <p>Sin descripción</p>
                              )}
                              {currentVariant.description ? (
                                <p>{currentVariant.description}</p>
                              ) : null}
                            </Col>
                          </Row>
                        </Container>
                        <Container className="border-top">
                          <Row className="mt-3">
                            <Col>
                              <h4 className="mb-3">Recomendaciones</h4>
                              {product.recommendation ? (
                                <p>{product.recommendation}</p>
                              ) : (
                                <p>Sin especificar</p>
                              )}
                              {currentVariant.recommendation ? (
                                <p>{currentVariant.recommendation}</p>
                              ) : null}
                            </Col>
                          </Row>
                        </Container>
                        {product.attributes && product.attributes.length > 0 ? (
                          <Container className="mt-3 border-top">
                            <Row className="mt-3">
                              <Col>
                                <h4 className="mb-3">Características</h4>
                                <Table striped bordered>
                                  <tbody>
                                    {product.attributes.map(
                                      (attribute, idx) => (
                                        <tr key={idx}>
                                          <td className="fw-bold">
                                            {attribute.name}
                                          </td>
                                          <td className="w-75">
                                            {attribute.value_name}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </Container>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </section>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Item;
