import { useState } from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/**
 * Creates an add/remove input for int numbers
 * @param {params} param0
 * @returns
 */
const InputQuantity = ({ id, quantity, min, max, onQuantityChanged }) => {
  const [qty, setQty] = useState(quantity);

  /**
   * Add units
   */
  const addQuantity = () => {
    const op = qty + 1;
    const res = op <= max ? op : max;
    setQty(res);
    onQuantityChanged({ id: id, quantity: res });
  };

  /**
   * Subtract units
   */
  const subtractQuantity = () => {
    const op = qty - 1;
    const res = op <= min ? min : op;
    setQty(res);
    onQuantityChanged({ id: id, quantity: res });
  };

  /**
   * Allow only numbers in quantity input
   * @param {*} e
   */
  const onQuantityChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setQty(e.target.value);
    }
  };

  const onQuantityBlur = (e) => {
    let val = parseInt(e.target.value);
    if (val <= min) {
      val = min;
    } else if (val >= max) {
      val = max;
    }
    setQty(val);
    onQuantityChanged({ id: id, quantity: val });
  };

  return (
    <Form.Group as={Col} lg="4" md="8" className="input-quantity">
      <Form.Label className="input-quantity-title">Cantidad:</Form.Label>
      <InputGroup className="mb-3 input-quantity-group">
        <Button
          className="sub-btn"
          variant="outline-secondary"
          onClick={subtractQuantity}
        >
          -
        </Button>
        <Form.Control
          type="text"
          className="counter text-center"
          name="quantity"
          value={qty}
          onChange={onQuantityChange}
          onBlur={onQuantityBlur}
        />
        <Button
          className="add-btn"
          variant="outline-secondary"
          onClick={addQuantity}
        >
          +
        </Button>
      </InputGroup>
    </Form.Group>
  );
};

InputQuantity.propTypes = {
  id: PropTypes.string,
  quantity: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onQuantityChanged: PropTypes.func,
};

InputQuantity.defaultProps = {
  id: null,
  quantity: 1,
  min: 1,
  max: 9999,
  onQuantityChanged: (f) => f,
};

export default InputQuantity;
