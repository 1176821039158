import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Api from "../Api";
import TinySlider from "tiny-slider-react";

const PartnersSlider = ({ hooks, limit }) => {
  const [list, setList] = useState([]);

  const settings = {
    loop: false,
    lazyload: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayButtonOutput: false,
    mouseDrag: true,
    items: 6,
    hasControls: false,
    controlsContainer: "#control-partners",
    responsive: {
      992: {
        items: 6,
      },
      768: {
        items: 5,
      },
      500: {
        items: 4,
      },
      1: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    Api.get("partners", {
      params: { hooks: hooks, limit: limit },
    }).then((res) => {
      setList(res.data);
    });

    // avoid memory leak on unmount
    return () => {
      setList([]);
    };
  }, [hooks, limit]);

  return (
    <>
      {list.length > 0 && (
        <div className="tns-carousel-wrapper tns-controls-outside-xxl tns-nav-outside tns-nav-outside-flush mx-n2 mb-3">
          <div id="control-partners">
            <button className="previous">
              <i className="fa fa-chevron-left"></i>
            </button>
            <button className="next">
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
          <TinySlider settings={settings}>
            {list.map((el, index) => (
              <div key={index} className="col p-2 graymask">
                <a
                  className="card"
                  href={el.url ? el.url : ""}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="card-img-top card-img-hover">
                    <span className="img-overlay opacity-65"></span>
                    <img src={el.img_src} alt="" />
                  </div>
                </a>
              </div>
            ))}
          </TinySlider>
        </div>
      )}
    </>
  );
};

PartnersSlider.propTypes = {
  hooks: PropTypes.bool,
  limit: PropTypes.number,
};

PartnersSlider.defaultProps = {
  hooks: false,
  limit: null,
};

export default PartnersSlider;
