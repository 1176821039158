import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import headerImg from "./../assets/img/error/header.jpg";

const NotFound = ({ title, crumbs }) => {
  PageTitle(title);

  const goBack = () => {
    const origin = window.location.origin;
    if (document.referrer.indexOf(origin) >= 0) {
      window.history.back();
    } else {
      window.location.href = origin; // this might just be '/' of your site
    }
  };

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <header className="page-header">
              <h1>La página que buscas no existe</h1>
            </header>
            <section id="content" className="page-content page-not-found">
              <h4>Lamentamos el inconveniente.</h4>
              <p className="text-center">Vuelve a la página anterior</p>
              <div id="search_widget" className="search-widget">
                <Button variant="primary" onClick={goBack}>
                  <i className="fa fa-chevron-left"></i>
                  {` Regresar`}
                </Button>
              </div>
            </section>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default NotFound;
