import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import { ShoppingBag } from "lucide-react";

/**
 * CartButton
 */
const CartButton = ({ data }) => {
  const { totalUniqueItems } = useCart();

  return (
    <Link to="/cart">
      <button type="button" className="btn position-relative">
        <ShoppingBag />
        <span className="badge position-absolute top-0 translate-middle text-dark">
          {totalUniqueItems}
        </span>
      </button>
    </Link>
  );
};

export default CartButton;
