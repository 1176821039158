import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import headerImg from "./../assets/img/develop/header.jpg";
import machineImg from "./../assets/img/develop/machine.jpg";
import Accordion from "react-bootstrap/Accordion";

const Development = ({ title, crumbs }) => {
  PageTitle(title);

  const dataList = [
    {
      title: "1. Todo comienza con una idea",
      content: (
        <>
          <p>
            En la actualidad se requiere un alto grado de especialización, desde
            el empaque y embalaje mismo del producto. Todo producto necesita un
            mayor nivel de diferenciación por el tipo de competencia que exige
            el mercado.
          </p>
          <p>
            En consecuencia, nuestro primer objetivo es vestir a tu empresa y
            otorgarle la notoriedad y presentación que haz visualizado para tu
            producto y/o marca.
          </p>
        </>
      ),
    },
    {
      title: "2. Es momento de darle viabilidad a tu proyecto",
      content: (
        <>
          <p>
            Este sencillo paso consiste en analizar el{" "}
            <b>costo-beneficio de tu idea.</b> Es común hacerse con la premisa
            de que personalizar un diseño es de coste sencillo, en la realidad
            el análisis va un poco más allá, puesto que desde la forma que puede
            tener un envase, hasta el método, los materiales y los recursos a
            emplear son puntos que desde un inicio se deben aclarar con el
            principal objetivo de que toda relación comercial tenga un beneficio
            económico en común.
          </p>
          <p>
            Todo esto se trata de <b>ganar-ganar.</b>
          </p>
        </>
      ),
    },
    {
      title: "3. Factibilidad del proyecto de desarrollo",
      content: (
        <>
          <p>
            Nos adentramos en los detalles técnicos, ergonómicos, productivos y
            funcionales. Aquí es necesario entrar en aspectos muy específicos
            puesto que nos encontramos en una etapa de aprobación y pruebas, en
            consecuencia debemos destacar los puntos centrales de acuerdo para
            poner en marcha el desarrollo.
          </p>
          <p>
            Son estos aspectos básicos los acuerdos comerciales respecto de la
            producción, la tecnología a emplear, el diseño que realmente vaya
            acorde a tus expectativas visuales y de rendimiento, se analizan
            alternativas y naturalmente se determinan preliminarmente costos y
            tiempos de entrega de acuerdo a tu plan de negocios.
          </p>
          <p>
            <b>
              Nos ajustamos trabajando en equipo para que tu idea sea rentable y
              vaya acorde a todas las expectativas generadas.
            </b>
          </p>
        </>
      ),
    },
    {
      title: "4. Diseño, optimización y fabricación del molde",
      content: (
        <>
          <p>
            En un sentido completamente práctico y técnico, al aprobarse el
            desarrollo viene un ciclo de tiempo acorde a los factores
            mencionados en el anterior punto. Por lo tanto, el tiempo dependerá
            en mayor parte de la complejidad del molde, pues se trata de que el
            rendimiento–calidad del mismo vayan de la mano con el precio que le
            permita llevar al mercado una idea exitosa.
          </p>
          <p>
            En consecuencia, los recursos tecnológicos, humanos, el uso de
            materiales, cronogramas de trabajo y la definición de los ciclos de
            producción deben cumplir con los parámetros, así como las pruebas y
            ajustes, que desde un principio se establecieron para generar una
            confianza plena y encontrar áreas de oportunidad para una mejora
            continua.
          </p>
          <p>
            <b>
              Se trata de que un molde eficiente le permita no sólo recuperar tu
              inversión en un corto plazo, sino que además se convierta en una
              herramienta de diferenciación y mejora de tu imagen como empresa y
              marca.
            </b>
          </p>
        </>
      ),
    },
    {
      title: "5. Implementación y autorización del proyecto",
      content: (
        <>
          <p>
            Entramos en la recta final para que la idea que tomó un aspecto
            físico y tangible se pueda llevar al mercado y cumpla los objetivos
            planteados.
          </p>
          <p>
            Se hace una primera corrida para comprobar que el funcionamiento
            desde pie de máquina hasta la funcionalidad del producto vaya acorde
            a los parámetros solicitados, de acuerdo a los mismos en ocasiones
            es necesario hacer ajustes para que se cumpla al 100% con el
            proyecto, se visualizan los recursos necesarios para realizar ya las
            producciones en firme acorde a un plan de producción, se solicita un
            inventario para no dejar de tener un abastecimiento adecuado y se
            hace un monitoreo para darle lo mejor de nosotros desde el aparato
            productivo hasta la logística de entrega o recolección que haga
            feliz a tu empresa.
          </p>
        </>
      ),
    },
    {
      title: "6. Seguimiento post-venta",
      content: (
        <>
          <p>
            Lo que buscamos en Grupo UNIVERPLAST México, es generar una relación
            comercial más estrecha, contemplando un mejor acercamiento contigo
            para buscar no sólo mejoras en el sistema de producción, dado que el
            plástico es un material muy flexible, sino nuevas áreas de
            oportunidad de negocios que vayan esencialmente ligadas a tu plan de
            crecimiento y desarrollo.
          </p>
          <p>
            Nuestros ejecutivos de venta no sólo están capacitados para tener
            una buena atención y respuesta, sino que además están comprometidos
            a servirte en cualquiera de las oportunidades que con el paso del
            tiempo se puedan presentar.
          </p>
          <p>
            <b>
              Uno de nuestros mayores éxitos es crecer contigo, buscar esquemas
              que nos permitan brindarte el máximo apoyo posible en pro de una
              relación comercial que trascienda en el tiempo.
            </b>
          </p>
        </>
      ),
    },
  ];

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <header className="page-header">
              <h1>Desarrolla con UNIVERPLAST</h1>
            </header>
            <section id="content" className="page-content page-cms page-cms-4">
              <div className="block">
                <div className="cms-row block block_top clearfix">
                  <Row>
                    <Col md={12} lg={4} className="part_1">
                      <div className="cms-box cms-box-img">
                        <img
                          className="img-responsive"
                          title="cms-img"
                          src={machineImg}
                          alt="cms-img"
                        />
                      </div>
                    </Col>
                    <Col md={12} lg={8} className="part_2">
                      <h3 className="page-subheading">Nuestro proceso</h3>
                      <Accordion defaultActiveKey={0}>
                        {dataList.map(({ title, content }, key) => (
                          <Accordion.Item key={key} eventKey={key}>
                            <Accordion.Header>{title}</Accordion.Header>
                            <Accordion.Body>{content}</Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Development;
