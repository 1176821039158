import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import placeholderImg from "../assets/img/categories/placeholder.jpg";

/**
 * Display a list with cards
 * @param {array} data
 * @returns
 */
const SubcategoryCards = ({ data, onClickItem }) => {
  return data.map(({ id, name, picture, link }) => (
    <Col key={id}>
      <div
        className="vertical-card h-100"
        onClick={() =>
          onClickItem({ id: id, name: name, picture: picture, href: link })
        }
      >
        <Row className="no-gutters">
          <Col lg={12} className="text-center">
            <Image
              className="card-img"
              src={picture}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = placeholderImg;
              }}
            />
          </Col>
          <Col lg={12}>
            <div className="card-body">
              <h5 className="card-title">{name}</h5>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  ));
};

SubcategoryCards.propTypes = {
  data: PropTypes.array,
  onClickItem: PropTypes.func,
};

SubcategoryCards.defaultProps = {
  data: [],
  onClickItem: () => {},
};

export default SubcategoryCards;
