import { NavLink } from "react-router-dom";

/**
 * Node
 * @param {*} param0
 * @returns
 */
const TreeNode = ({ node, childs }) => {
  let nodes;

  if (childs) {
    nodes = childs.map((i) => (
      <TreeNode key={i.id} node={i} childs={i.childs} />
    ));
  }

  return (
    <li>
      {node.link === "#" ? (
        <span>{node.name}</span>
      ) : (
        <NavLink to={node.link} className="link">
          {node.name}
        </NavLink>
      )}
      {nodes ? <ul>{nodes}</ul> : null}
    </li>
  );
};

export default TreeNode;
