import { forwardRef } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "react-phone-number-input/style.css";

export const PhoneNumberInput = (props, ref) => {
  return (
    <FloatingLabel controlId="floatingPhone" label={props.label}>
      <Form.Control ref={ref} {...props} />
    </FloatingLabel>
  );
};
export default forwardRef(PhoneNumberInput);
