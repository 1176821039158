import React from "react";

const Context = React.createContext();
const UPDATE_PAGES = "UPDATE_PAGES";
const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
const initialState = { pages: [], categories: [] };

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PAGES:
      return { ...state, pages: action.payload };
    case UPDATE_CATEGORIES:
      eachCategory(action.payload);
      return { ...state, categories: action.payload };
    default:
      return initialState;
  }
};

function ContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useContext() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error("useContext must be used within a ContextProvider");
  }
  return context;
}

// helpers
/**
 * Creates app links for categories
 * @param {array} obj
 */
function eachCategory(obj) {
  for (var k in obj) {
    if (typeof obj[k] === "object" && obj[k] !== null) eachCategory(obj[k]);
    else {
      obj["link"] = `/categorias/${obj["id"]}-${obj["slug"]}`;
    }
  }
}

export { ContextProvider, useContext };
