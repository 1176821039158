import React, { useEffect, useState } from "react";
import { useContext } from "./../AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import Api from "../Api";
import axios from "axios";
import CategorySelector from "../components/CategorySelector";
import SubcategoryCards from "../components/SubcategoryCards";
import ItemCardV from "../components/ItemCardV";
import ItemCardH from "../components/ItemCardH";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "../components/Pagination";
import headerImg from "./../assets/img/products/header.jpg";

// async axios request
let request;

const Products = ({ title, crumbs }) => {
  // router
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let location = useLocation();

  // responsive
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 768;

  // functionality
  const [isLoading, setIsLoading] = useState(false);
  const [itemList, setItemList] = useState([]);
  const {
    state: { categories },
  } = useContext();
  const [levelCategories, setLevelCategories] = useState([]);

  /**
   * Fetch products by a given node category
   *
   * @param {object} category
   */
  const fetchCategoryProducts = (category) => {
    if (category) {
      navigate(category.href);
    }
  };

  /**
   * On page changed
   * @param {} pagination
   */
  const onPageChanged = (pagination) => {
    setSearchParams({ pgn: pagination.currentPage });
  };

  useEffect(() => {
    // Categories must be loaded first
    if (categories.length > 0) {
      // url
      const categorySegment = location.pathname.split("/").pop();
      let categoryID = categorySegment.split("-", 1).toString();
      // if is empty or not numeric
      if (!categoryID || categoryID.length === 0 || isNaN(categoryID)) {
        categoryID = 0;
      }

      /**
       * Finds a node in array by id
       * @param {int} id
       * @param {array} arr
       * @returns
       */
      const findCategoryNodeById = (id, arr) => {
        return arr.reduce((a, item) => {
          if (a) return a;
          if (item.id === id) return item;
          if (item.childs) return findCategoryNodeById(id, item.childs);
          return null;
        }, null);
      };

      /**
       * Set subcategories
       * @param {int} categoryID
       */
      const setSubcategories = (categoryID) => {
        if (categoryID === 0) {
          let displayCategories = [];
          categories.forEach((category) => {
            displayCategories.push({ ...category });
          });
          setLevelCategories(displayCategories);
        } else {
          const node = findCategoryNodeById(categoryID, categories);
          setLevelCategories(node?.childs ? node?.childs : []);
        }
      };

      /**
       * Fetch products per category
       * @param {int} categoryID
       * @param {int} limit
       * @param {int} page
       */
      const fetchProducts = (categoryID, query, page, limit) => {
        // set params
        let formatParams = {};
        if (categoryID > 0) {
          formatParams.category = categoryID;
        }

        if (query) {
          formatParams.q = query;
        }

        if (page > 0) {
          formatParams.page = page;
        }

        if (limit > 0) {
          formatParams.limit = limit;
        }

        if (Object.keys(formatParams).length > 0) {
          setIsLoading(true);
          // cancel previous request
          if (request) request.cancel();
          // create a new cancel token
          request = axios.CancelToken.source();
          // Fetch
          Api.get("products", {
            cancelToken: request.token,
            params: formatParams,
          })
            .then((res) => {
              setItemList(res.data);
              setIsLoading(false);
            })
            .catch((error) => {
              // console.log(error);
            });
        }
      };

      setSubcategories(categoryID);
      fetchProducts(
        categoryID,
        searchParams.get("q"),
        searchParams.get("pgn"),
        searchParams.get("ipg")
      );
    }

    // Responsive handler
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [categories, location.pathname, searchParams]);

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <Row>
              <Col lg={3} xs={12} className="mb-4">
                <h6 className="page-subheading">Categorías</h6>
                <CategorySelector
                  data={categories}
                  onCategorySelect={fetchCategoryProducts}
                />
              </Col>
              <Col lg={9} xs={12}>
                {/* Render subcategories on desktop */}
                {width > breakpoint && levelCategories.length > 0 && (
                  <Row className="mb-5">
                    <h6 className="page-subheading">Subcategorías</h6>
                    <Row xs={2} md={4} className="g-3 d-none d-md-flex">
                      <SubcategoryCards
                        data={levelCategories}
                        onClickItem={fetchCategoryProducts}
                      />
                    </Row>
                  </Row>
                )}
                {/* Render product data only if a category is selected */}
                <Row>
                  <h6 className="page-subheading">Productos</h6>
                  <div className="d-flex justify-content-center">
                    <p>
                      {isLoading
                        ? "Cargando..."
                        : itemList.pagination &&
                          (itemList.pagination.total === 0
                            ? "Sin resultados"
                            : itemList.pagination.total === 1
                            ? `${itemList.pagination.total} resultado`
                            : `${itemList.pagination.total} resultados`)}
                    </p>
                  </div>
                  {width < breakpoint ? (
                    isLoading ? (
                      // render some placeholders
                      [...Array(3)].map((_, i) => (
                        <ItemCardH key={i} asPlaceholder={true} />
                      ))
                    ) : (
                      // render data found
                      itemList.items?.map((item) => (
                        <ItemCardH key={item.id} item={item} />
                      ))
                    )
                  ) : (
                    <Row xs={1} md={3} className="g-2">
                      {isLoading
                        ? // render some placeholders
                          [...Array(3)].map((_, i) => (
                            <ItemCardV key={i} asPlaceholder={true} />
                          ))
                        : // render data found
                          itemList.items?.map((item) => (
                            <ItemCardV key={item.id} item={item} />
                          ))}
                    </Row>
                  )}
                  <div className="d-flex mt-3 mb-3 justify-content-center">
                    {!isLoading && itemList.pagination && (
                      <Pagination
                        pageNumber={itemList.pagination.cPage}
                        totalRecords={itemList.pagination.total}
                        pageLimit={itemList.pagination.limit}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                      />
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Products;
