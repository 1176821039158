import { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Treeview plugin
 */
const $ = window.jQuery;
const Treeview = ({ data }) => {
  useEffect(() => {
    $("#treeview").treeview({
      color: "#428bca",
      showBorder: false,
      expandIcon: "fa fa-chevron-right",
      collapseIcon: "fa fa-chevron-down",
      enableLinks: false,
      data: data,
      onNodeSelected: function (event, node) {
        if (node.href) {
          window.open(node.href, "_blank");
        }
      },
    });
  }, [data]);

  return <div id="treeview" />;
};

Treeview.propTypes = {
  data: PropTypes.array,
};

Treeview.defaultProps = {
  data: [],
};

export default Treeview;
