import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
  transformRequest: [
    (data) => {
      // format request params for x-www-form-urlencoded
      // issue https://gist.github.com/akexorcist/ea93ee47d39cf94e77802bc39c46589b
      if (data) {
        return JSON.stringify(data);
      }
    },
  ],
});
