import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import headerImg from "./../assets/img/privacy/header.jpg";

const Privacy = ({ title, crumbs }) => {
  PageTitle(title);

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <Row className="mb-5">
              <Col md={12}>
                <h4>Generalidades</h4>
                <p>
                  Grupo UNIVERPLAST México cuenta con una clara visión de la
                  importancia de valores como los son la honestidad, lealtad y
                  sobre todo confidencialidad tanto como de sus clientes,
                  proveedores y la propia empresa. Es por ello que el cliente
                  puede estar seguro de que sus datos personales están en buenas
                  manos y de ninguna forma se hará un mal uso de estos mismos.
                  El propósito de las Políticas de Privacidad será ante todo
                  protegerlo como usuario de nuestra página y así mismo proteger
                  sus datos personales.
                </p>
                <br />
                <h4>Su aceptación a esta política de privacidad</h4>
                <p>
                  Al proporcionar información personal a Grupo UNIVERPLAST
                  México, usted confirma que está de acuerdo con los términos de
                  nuestra política actual tal y como se presenta en este
                  documento. Si no está de acuerdo con cualquier término de esta
                  política, por favor no proporcione ningún tipo de información
                  personal. Si opta por no proporcionar información personal, es
                  posible que no pueda llevar a cabo ciertas actividades como
                  acceso a diversas áreas de nuestras páginas de internet, como
                  puede ser: registro, promociones, ofertas, boletines, requerir
                  muestras o enviarnos solicitudes personalizadas.
                </p>
                <br />
                <h4>Naturaleza de la información</h4>
                <p>
                  Toda la información proporcionada en este sitio, es verídica y
                  confiable, tanto de productos como de servicios, es importante
                  mencionar que no está permitido el plagio de la información,
                  esta sólo podrá ser utilizada con fines informativos e
                  ilustrativos.
                </p>
                <br />
                <h4>Uso y protección de datos personales</h4>
                <p>
                  La información extraída para la creación de una cuenta de
                  nuestra página únicamente tiene los siguientes propósitos:
                  <br />
                  <br />
                  - Realizar cotizaciones en línea
                  <br />
                  - Proporcionar servicio y apoyo
                  <br />
                  - Ponerlo al día sobre nuevos servicios y promociones.
                  <br />
                  - Contactar con usted para estudios de mercado con respecto a
                  productos o servicios de Grupo UNIVERPLAST México.
                  <br />
                  - Realizar compras en línea.
                  <br />
                  - Darle a conocer información acerca de productos y servicios
                  de su interés.
                  <br />
                  - Comunicarle acerca de cambios o actualizaciones que puedan
                  interesarle.
                  <br />
                  <br />
                  Usted puede estar seguro de que la información personal de su
                  cuenta como usuario, únicamente podrá ser utilizada por
                  aquellos empleados que requieran conocerla con objeto de
                  operar, desarrollar o mejorar los servicios de Grupo
                  UNIVERPLAST México.
                </p>
                <br />
                <h4>Confidencialidad de la información</h4>
                <p>
                  Una vez inscrito en nuestro sitio Web, Grupo UNIVERPLAST
                  México no venderá, alquilará o compartirá la información
                  personal excepto en las formas establecidas en estas
                  políticas. Sin perjuicio de ello, el usuario consiente en
                  forma expresa que UNIVERPLAST transfiera total o parcialmente
                  la información personal a cualquiera de áreas de la empresa o
                  grupo.
                </p>
                <br />
                <h4>Sitios enlazados</h4>
                <p>
                  A través de los sitios enlazados, Grupo UNIVERPLAST México
                  pone a disposición del usuario dispositivos técnicos de
                  enlace, tales como hipervínculos (links), anuncios (banners),
                  botones, directorios y/o herramientas de búsqueda, entre
                  otros, que le permiten acceder a páginas de Internet
                  pertenecientes a terceros. Mediante el uso de los sitios
                  enlazados, el usuario reconoce y acepta que UNIVERPLAST no
                  controla y no garantiza la calidad, ausencia de virus o
                  similares, en el contenido de los sitios enlazados, ni la
                  ausencia de otros elementos que pudieran producir alteraciones
                  en sus sistemas, equipo o en los documentos electrónicos y/o
                  archivos almacenados en sus sistemas y/o equipo informáticos.
                </p>
                <br />
                <h4>Conducta responsable</h4>
                <p>
                  Toda la información que facilite deberá ser veraz. A estos
                  efectos, usted garantiza la autenticidad de todos aquellos
                  datos que comunique como consecuencia de la cumplimentación de
                  los formularios necesarios para la suscripción de los
                  servicios. En todo caso usted será el único responsable de las
                  manifestaciones falsas o inexactas que realice y de los
                  perjuicios que cause a este sitio o a terceros por la
                  información que facilite. Usted se compromete a actuar en
                  forma responsable en este sitio y a tratar a otros visitantes
                  con respeto.
                </p>
                <br />
                <h4>Menores de edad</h4>
                <p>
                  Nuestros servicios sólo están disponibles para aquellas
                  personas que tengan capacidad legal para contratar. Por lo
                  tanto, aquellos que no cumplan con esta condición deberán
                  abstenerse de suministrar información personal para ser
                  incluida en nuestras bases de datos.
                </p>
                <br />
                <h4>Cookies</h4>
                <p>
                  Un cookie es un pequeño archivo removible de datos que es
                  guardado por su navegador de Internet en su computadora u
                  ordenador. Los cookies le permiten establecer un orden en
                  nuestro sitio de Internet y nos permiten personalizar su
                  navegación en línea y su experiencia de compra.
                  <br />
                  <br />
                  Por ejemplo, utilizamos cookies para:
                  <br />
                  • Recordar sus productos en su carrito de compras.
                  <br />
                  • Reconocerle cuando regresa a nuestro sitio.
                  <br />
                  • Analizar la conducta del visitante.
                  <br />
                  <br />
                  Usted puede configurar su computadora u ordenador para que le
                  advierta cada vez que enviamos un cookie o puede elegir
                  desactivar todos los cookies. Cada navegador es un poco
                  distinto de los otros, así que para aprender la manera
                  correcta de modificar su configuración con respecto a los
                  cookies es mejor ir al Menú Ayuda (Help) del mismo. Si usted
                  desactiva sus cookies, no tendrá acceso a varios aspectos del
                  sitio que hacen su experiencia de navegación más eficiente y
                  algunos de nuestros servicios no funcionarán apropiadamente.
                </p>
                <br />
                <h4>Cambios en el aviso de privacidad</h4>
                <p>
                  Grupo UNIVERPLAST México actualizará esta política de
                  privacidad según corresponda, le invitamos a consultar esta
                  política en forma periódica con el fin de mantenerse informado
                  sobre las medidas adoptadas por UNIVERPLAST para proteger su
                  información personal.
                </p>
              </Col>
            </Row>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Privacy;
