import PageTitle from "../components/hooks/PageTitle";
import Breadcrumbs from "../components/Breadcrumbs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import headerImg from "./../assets/img/partners/header.jpg";
import businessImg from "./../assets/img/partners/negocios.jpg";
import PartnersSlider from "../components/PartnersSlider";

const Partners = ({ title, crumbs }) => {
  PageTitle(title);

  return (
    <section id="wrapper" className="active_grid">
      <Breadcrumbs photo={headerImg} crumbs={crumbs} />
      <Container>
        <div id="content-wrapper">
          <section id="main">
            <header className="page-header">
              <h1>Nuestros clientes</h1>
            </header>
            <section id="content" className="page-content page-cms page-cms-4">
              <div
                id="cms-about-us"
                className="block"
                style={{ textAlign: "left" }}
              >
                <div className="cms-row block block_top clearfix">
                  <Row className="pb-5">
                    <Col className="part_1" xs={12} md={12} lg={6}>
                      <div className="cms-box cms-box-img">
                        <Image src={businessImg} className="img-responsive" />
                      </div>
                    </Col>
                    <Col className="part_2" xs={12} md={12} lg={6}>
                      <h3 className="page-subheading">Tu mejor decisión</h3>
                      <h4>Hacer negocios con UNIVERPLAST es muy sencillo</h4>
                      <div className="cms-box">
                        <p>
                          Desde nuestros inicios hasta hoy en día, miles de
                          clientes nos han acompañado y muchos de ellos han
                          crecido junto a nosotros.
                        </p>
                        <p>
                          Todos ellos, pequeños o grandes, particulares o
                          empresas, tienen una historia interesante que contar.
                        </p>
                        <h5>Forma ya parte de UNIVERPLAST,</h5>
                        <h5>Ideas hechas plástico.</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-5">
                    <Container>
                      <PartnersSlider />
                    </Container>
                  </Row>
                </div>
              </div>
            </section>
          </section>
        </div>
      </Container>
    </section>
  );
};

export default Partners;
