import { useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Api from "./../Api";
import axios from "axios";

// Used to cancel previous requests
let request;

const InputSearch = () => {
  const [queryURL] = useState("");
  const [term, setTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleOnSearch = (string, results) => {
    let typedTerm = string.trim();

    if (typedTerm && typedTerm.length >= 2) {
      setTerm(typedTerm);
      setTimeout(() => {
        // cancel previous request
        if (request) request.cancel();

        // create a new cancel token
        request = axios.CancelToken.source();

        // request
        Api.get("products", {
          cancelToken: request.token,
          params: { q: typedTerm, limit: 5 },
        })
          .then((res) => {
            const retrievedSuggestions = [];
            res.data.items.forEach((product) => {
              retrievedSuggestions.push({
                id: product.id,
                name: product.title,
                permalink: product.permalink,
              });
            });
            // workaround for dynamic remote data:
            // request this function again
            // without string params
            setSuggestions(() => {
              handleOnSearch("", retrievedSuggestions);
              return retrievedSuggestions;
            });
          })
          .catch((error) => {
            // console.log(error);
          });
      }, 500);
    }
  };

  const handleOnSelect = (item) => {
    var url = new URL(item.permalink);
    window.location.href = url;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const productsURL = new URL(`/productos/?q=${term}`, [window.location]);
    window.location.href = productsURL;
  };

  const formatResult = (item) => {
    return item;
  };

  return (
    <form action="" onSubmit={handleSubmit}>
      <ReactSearchAutocomplete
        items={suggestions}
        onSearch={handleOnSearch}
        onSelect={handleOnSelect}
        formatResult={formatResult}
        placeholder={"Busca productos..."}
        inputDebounce={0} // debounce is in handleSearch
        styling={{
          zIndex: 99,
          backgroundColor: "#fff",
        }}
        inputSearchString={queryURL}
      />
    </form>
  );
};

export default InputSearch;
