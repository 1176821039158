import { useEffect } from "react";

/**
 * Set the page title
 *
 * @param {*} title
 */
const PageTitle = (title) => {
  useEffect(() => {
    document.title = title + " | Grupo UNIVERPLAST México";
  }, [title]);
};

export default PageTitle;
