import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../Api";
import PageTitle from "../components/hooks/PageTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import bottleImg from "./../assets/img/home/slider/slider-botella.jpg";
import homeImg from "./../assets/img/home/slider/slider-hogar.jpg";
import palletImg from "./../assets/img/home/slider/slider-tarimas.jpg";
import lineaHogarImg from "./../assets/img/home/linea_hogar.jpg";
import PartnersSlider from "../components/PartnersSlider";
import TinySlider from "tiny-slider-react";
import trolleyImg from "./../assets/img/home/competition/trolley-items.svg";
import certificateImg from "./../assets/img/home/competition/certificate.svg";
import deliveryImg from "./../assets/img/home/competition/delivery-truck.svg";
import handshakeImg from "./../assets/img/home/competition/handshake.svg";
import placeholderImg from "./../assets/img/categories/placeholder.jpg";
// categories
import contenedoresImg from "./../assets/img/home/categories/contenedores.jpg";
import envasesImg from "./../assets/img/home/categories/envases.jpg";
import hogarImg from "./../assets/img/home/categories/hogar.jpg";
import tambosImg from "./../assets/img/home/categories/tambos.jpg";
import tarimasImg from "./../assets/img/home/categories/tarimas.jpg";
import tinacosImg from "./../assets/img/home/categories/tinacos.jpg";

const Home = ({ title }) => {
  PageTitle(title);

  const [latestProducts, setLatestProducts] = useState([]);

  const settings = {
    loop: false,
    lazyload: true,
    mouseDrag: true,
    items: 6,
    hasControls: false,
    controlsContainer: "#controls",
    responsive: {
      1366: {
        items: 6,
      },
      1024: {
        items: 4,
      },
      767: {
        items: 2,
      },
      1: {
        items: 2,
      },
    },
  };

  useEffect(() => {
    Api.get("products", {
      params: {
        limit: 10,
      },
    })
      .then((res) => {
        setLatestProducts(res.data.items);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  return (
    <>
      <section id="wrapper" className="active_grid pb-2">
        {/* Hero */}
        <Carousel id="heroCarousel" indicators={false} fade>
          <Carousel.Item>
            <Col className="carousel-top-container">
              <div className="stack-back">
                <Image src={bottleImg} />
              </div>
              <div className="stack-top">
                <Container className="h-100">
                  <Row className="ps-4 h-100">
                    <Row className="align-items-end">
                      <div className="text-center">
                        <p className="desc-header text-white">
                          Alta resistencia
                        </p>
                        <h1 className="header text-danger">Botellas</h1>
                      </div>
                    </Row>
                    <Row className="align-items-end">
                      <div className="text-center mb-3">
                        <h4 className="text-white about-slide pb-4">
                          ¿Necesitas un diseño personalizado?
                          <br />
                          ¡Nosotros lo hacemos realidad!
                        </h4>
                        <Link to="/categorias/14-envases">
                          <Button variant="primary">Ver productos</Button>
                        </Link>
                      </div>
                    </Row>
                  </Row>
                </Container>
              </div>
            </Col>
          </Carousel.Item>
          <Carousel.Item>
            <Col className="carousel-top-container">
              <div className="stack-back">
                <Image src={homeImg} />
              </div>
              <div className="stack-top">
                <Container className="h-100">
                  <Row className="ps-4 h-100">
                    <Row className="align-items-end">
                      <div className="text-center">
                        <p className="desc-header text-white">
                          Soluciones para tu casa
                        </p>
                        <h1 className="header text-info">Hogar</h1>
                      </div>
                    </Row>
                    <Row className="align-items-end">
                      <div className="text-center mb-3">
                        <h4 className="text-white about-slide pb-4">
                          ¡Sillas, mesas, bancos y mucho más!
                        </h4>
                        <Link to="/categorias/36-hogar">
                          <Button variant="primary">Ver productos</Button>
                        </Link>
                      </div>
                    </Row>
                  </Row>
                </Container>
              </div>
            </Col>
          </Carousel.Item>
          <Carousel.Item>
            <Col className="carousel-top-container">
              <div className="stack-back">
                <Image src={palletImg} />
              </div>
              <div className="stack-top">
                <Container className="h-100">
                  <Row className="ps-4 h-100">
                    <Row className="align-items-end">
                      <div className="text-center">
                        <p className="desc-header text-white">
                          Optimiza tu espacio
                        </p>
                        <h1 className="header text-success">Tarimas</h1>
                      </div>
                    </Row>
                    <Row className="align-items-end">
                      <div className="text-center mb-3">
                        <h4 className="text-white about-slide pb-4">
                          Tenemos una amplia gama de resistencias
                          <br />
                          para patín, montacargas y racks.
                        </h4>
                        <Link to="/categorias/55-tarimas">
                          <Button variant="primary">Ver productos</Button>
                        </Link>
                      </div>
                    </Row>
                  </Row>
                </Container>
              </div>
            </Col>
          </Carousel.Item>
        </Carousel>
        {/* Categorias importantes */}
        <section className="container mb-5">
          <Row xs={2} md={3} className="g-3 d-flex">
            <Col className="category-card p-2">
              <Link to="/categorias/1-contenedores">
                <div className="card-img-top card-img-hover">
                  <Image src={contenedoresImg} />
                </div>
              </Link>
            </Col>
            <Col className="category-card p-2">
              <Link to="/categorias/14-envases">
                <div className="card-img-top card-img-hover">
                  <Image src={envasesImg} />
                </div>
              </Link>
            </Col>
            <Col className="category-card p-2">
              <Link to="/categorias/36-hogar">
                <div className="card-img-top card-img-hover">
                  <Image src={hogarImg} />
                </div>
              </Link>
            </Col>
            <Col className="category-card p-2">
              <Link to="/categorias/54-tambores">
                <div className="card-img-top card-img-hover">
                  <Image src={tambosImg} />
                </div>
              </Link>
            </Col>
            <Col className="category-card p-2">
              <Link to="/categorias/55-tarimas">
                <div className="card-img-top card-img-hover">
                  <Image src={tarimasImg} />
                </div>
              </Link>
            </Col>
            <Col className="category-card p-2">
              <Link to="/categorias/56-tinacos-cisternas">
                <div className="card-img-top card-img-hover">
                  <Image src={tinacosImg} />
                </div>
              </Link>
            </Col>
          </Row>
        </section>
        {/* Producto nuevo */}
        <section className="container mb-5">
          <Row>
            <Col md={9} sm={12}>
              <h3 className="title_text">Nuevos productos</h3>
            </Col>
            <Col md={3} sm={12} className="text-right">
              <Link to="/categorias">
                <Button variant="primary">Ver categorías</Button>
              </Link>
            </Col>
            {latestProducts.length > 0 && (
              <Col md={12} className="mt-3">
                <div className="tns-carousel-wrapper tns-controls-outside-xxl tns-nav-outside tns-nav-outside-flush mx-n2">
                  <div id="controls">
                    <button className="previous">
                      <i className="fa fa-chevron-left"></i>
                    </button>
                    <button className="next">
                      <i className="fa fa-chevron-right"></i>
                    </button>
                  </div>
                  <TinySlider settings={settings}>
                    {latestProducts.map((item, index) => (
                      <Col key={index} className="product-card p-2">
                        <a className="card card-hover" href={item.permalink}>
                          <div className="card-img-top card-img-hover">
                            <Image
                              src={
                                item.thumbnail ? item.thumbnail : placeholderImg
                              }
                              className="p-2"
                            />
                          </div>
                          <div className="card-body">
                            <h3 className="product-name">{item.title}</h3>
                          </div>
                        </a>
                      </Col>
                    ))}
                  </TinySlider>
                </div>
              </Col>
            )}
          </Row>
        </section>
        {/* Linea hogar */}
        <section id="cm-banner" className="py-3 position-relative">
          <Image src={lineaHogarImg} />
          <Container className="py-4">
            <Row>
              <Col md={9} sm={12}>
                <h3 className="title_text">Línea hogar</h3>
                <div className="sub_title mb-3">
                  Contamos con una gran variedad de productos para jardinería y
                  el hogar.
                </div>
              </Col>
              <Col md={3} sm={12} className="text-right">
                <Link to="/categorias/36-hogar">
                  <Button variant="primary">Ver productos</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Clientes */}
        <section>
          <Container className="py-4">
            <Row>
              <Col md={9} sm={12}>
                <h3 className="title_text">Nuestros clientes</h3>
                <div className="sub_title mb-3">
                  Empresas que confían en la calidad UNIVERPLAST
                </div>
              </Col>
              <Col md={3} sm={12} className="text-right">
                <Link to="/clientes">
                  <Button variant="primary">Ver empresas</Button>
                </Link>
              </Col>
              <Col md={12} className="mt-3">
                <PartnersSlider hooks={true} />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Ventajas */}
        <section>
          <Row className="pt-5">
            <Container>
              <div className="top-title">
                <h4 className="title_block title_font">Garantía</h4>
              </div>
            </Container>
            <Container>
              <div className="g-3 row row-cols-2 row-cols-md-4">
                <div className="col competition-card">
                  <div className="competition-content">
                    <div className="row no-gutters">
                      <div className="col-12 text-center">
                        <Image className="card-img" src={trolleyImg} />
                      </div>
                      <div className="col-12">
                        <div className="card-body">
                          <h5 className="card-title text-center">
                            Non stop solution
                          </h5>
                          <p className="card-text text-center">
                            Encuentra la mayor variedad de artículos de plástico
                            aquí.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col competition-card">
                  <div className="competition-content">
                    <div className="row no-gutters">
                      <div className="col-12 text-center">
                        <Image className="card-img" src={certificateImg} />
                      </div>
                      <div className="col-12">
                        <div className="card-body">
                          <h5 className="card-title text-center">
                            Garantía de calidad
                          </h5>
                          <p className="card-text text-center">
                            Devolución sobre defectos de producción SIN
                            penalizaciones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col competition-card">
                  <div className="competition-content">
                    <div className="row no-gutters">
                      <div className="col-12 text-center">
                        <Image className="card-img" src={deliveryImg} />
                      </div>
                      <div className="col-12">
                        <div className="card-body">
                          <h5 className="card-title text-center">
                            Envío Gratuito*
                          </h5>
                          <p className="card-text text-center">
                            En producción dentro de CDMX y Área Metropolitana.
                          </p>
                          <p className="card-text text-center">
                            *Aplican Restricciones
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col competition-card">
                  <div className="competition-content">
                    <div className="row no-gutters">
                      <div className="col-12 text-center">
                        <Image className="card-img" src={handshakeImg} />
                      </div>
                      <div className="col-12">
                        <div className="card-body">
                          <h5 className="card-title text-center">
                            Acompañamiento en la innovación
                          </h5>
                          <p className="card-text text-center">
                            Te llevamos desde el desarrollo de una IDEA hasta la
                            IDEA hecha plástico.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Row>
        </section>
      </section>
    </>
  );
};

export default Home;
